<template>


      <div class="d-flex align-self-center  flex-column justify-center pa-4 mx-auto ma-10" v-show="showCropper===false">
        <v-card min-width="350" max-width="350" class="align-self-center text-center justify-center" style="opacity: .85;">
          <v-card-title class="card_ligth_header">
            <h5>Мой профиль</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="profile">
              <v-avatar  v-if="myProfile.personAvatar!==null" size="128" style="margin: 10px;" @click="showCropper=true" >
                <v-img
                    :src="'/avatar/' + myProfile.personAvatar"
                    alt="John"
                ></v-img>
              </v-avatar>
              <v-avatar v-else size="128" style="margin: 10px;" @click="showCropper=true" color="primary">
                <span  class="text-h5">{{myProfile.firstname}}</span>
              </v-avatar>
              <h5 class="text-h6" style="margin: 10px;">{{myProfile.firstname}} {{myProfile.lastname}}</h5>
              <v-divider></v-divider>

              <v-text-field
                  ref="email"
                  v-model="personEmail"
                  :rules="[() => !!personEmail || 'Поле обязательно']"
                  :error-messages="errorMessages"
                  label="E-mail"
                  placeholder="my@mail.addr"
                  variant="outlined"
                  required
              ></v-text-field>

              <v-alert
                  density="compact"
                  type="error"
                  v-show="emailExistAlert"
              >  E-mail уже занят!
              </v-alert>

              <v-text-field
                  ref="phone"
                  v-model="personMobilePhone"
                  :rules="[() => !!personMobilePhone || 'Поле обязательно',
                            () => !!personMobilePhone && personMobilePhone.length <= 25 || 'Не более 18 символов']"
                  label="Телефон"
                  placeholder="+7-(999)-99-99-999"
                  counter="18"
                  variant="outlined"
                  required
              ></v-text-field>
              <v-col
                  cols="12"
              >
                <v-switch
                    v-model="inHall"
                    variant="outlined"
                    label="Очное участие"

                ></v-switch>
              </v-col>

              <v-col
                  cols="12"
              >
                <v-switch
                    v-model="inOnline"
                    variant="outlined"
                    label="Онлайн участие"

                ></v-switch>
              </v-col>

              <v-text-field
                  v-model="password"
                  label="Пароль*"
                  contenteditable="false"
                  type="text"
                  hint="Сгенерированный пароль"
                  required
                  persistent
                  variant="outlined"
              >
                <template v-slot:append>
                  <v-icon
                      color="red"
                      v-on:click="GeneratePassword"
                  >
                    mdi-hammer
                  </v-icon>
                </template>
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-end card_ligth_actions">
            <v-btn
                variant="outlined"
                rounded
                text @click="saveData"><font-awesome-icon icon="fas fa-save" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="rounded vue-avatar-cropper-demo text-center">
            <avatar-cropper
                @uploading="handleUploading"
                @uploaded="handleUploaded"
                @completed="handleCompleted"
                @error="handlerError"
                v-model="showCropper"
                upload-url="https://school-backend.eurekacenter.ru/api/upload/avatar"
                :request-options="headers"
                :labels="labels"
            />
        </div>
</template>


<script>
  import {apiUrl} from "@/service/user.service";
  import {router} from "@/service/router";
  import AvatarCropper from 'vue-avatar-cropper';
  export default {
  name: 'ProfileCard',
    components: { AvatarCropper },
    data: () => ({
      errorMessages: '',
      personEmail: undefined,
      emailExistAlert: false,
      personMobilePhone: undefined,
      password: null,
      formHasErrors: false,
      myProfile: '',
      showCropper: true,
      inHall: false,
      inOnline: false,
      message:'',
      labels:{ submit: 'Сохранить', cancel: 'Отмена' }

    }),
    computed:{
      loggedIn() {
        return this.$store.state.authentication.status.loggedIn
      },
      user() {
        return this.$store.state.authentication.user
      },
      authentication() {
        return this.$store.state.authentication
      },

    headers(){
        let jwtTokenConfig = {
          method: 'POST',
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
          responseType: 'blob'
      }
      return jwtTokenConfig
    }
    },

    methods: {
      handleUploading() {
        this.message = "uploading...";
        console.log("MSG: " + this.message)
      },

      handleUploaded(response) {
        if (response.status === "success") {
          //this.user.avatar = response.url;
          // Maybe you need call vuex action to
          // update user avatar, for example:
          // this.$dispatch('updateUser', {avatar: response.url})
          this.message = "user avatar updated."
          console.log("MSG: " + this.message)
          router.push('/profile').then(r => {
            console.log("error: " + r)
          })
        }
      },

      handleCompleted() {
        this.message = "upload completed.";
        console.log("MSG: " + this.message)

        let jwtTokenConfig = {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        }

        this.axios
            .get(apiUrl + '/user/i', jwtTokenConfig)
            .then(res => {
              this.myProfile = res.data
              this.personEmail = res.data.personEmail
              this.personMobilePhone = res.data.personMobilePhone

              console.log('myProfile: ', res.data)

            }).catch(error => {
          console.log(error)
          if (error.response.status === 401) {
            router.push('/login').then(r => {
              console.log("error: " + r)
            })
          }

        })

      },

      handlerError() {
        this.message = "Oops! Something went wrong...";
        console.log("MSG: " + this.message)
      },

      saveData() {
        if (this.$refs.profile.validate()) {


          if (this.personEmail.length > 0) {

            let jwtTokenConfig = {
              headers: {
                Authorization: "Bearer " + this.user.token
              }
            }
            console.log("User Form validated")
            this.axios
                .get(apiUrl + '/user/check/' + this.personEmail.toLowerCase(), jwtTokenConfig)
                .then(res => {
                  this.emailForCheck = res.data;
                  console.log('emailForCheck: ', res.data);
                  let person = {
                    id: this.myProfile.id,
                    personEmail: this.personEmail,
                    password: this.password,
                    personMobilePhone: this.personMobilePhone,
                    userEmail: this.userEmail,
                    inOnline: this.inOnline,
                    inHall: this.inHall,
                  }

                  if (this.emailForCheck.existEmail) {
                    if ((this.emailForCheck.ownerId === this.myProfile.id) && (this.emailForCheck.email === this.personEmail)) {

                      this.axios.put(apiUrl + '/user/i/' + this.myProfile.id, person, jwtTokenConfig)
                          .then(response => {
                            if (response.status === 202) {
                              this.myProfile = response.data
                              this.emailExistAlert = false


                            } else {
                              alert("что то пошло не так")
                            }
                            console.log(response)
                          })
                          .catch(function (error) {
                            console.log(error)
                            if (error.response.status === 401) {
                              router.push('/login').then(r => {
                                console.log("error: " + r)
                              })
                            }
                          })
                    } else {
                      this.emailExistAlert = true
                    }
                  } else {
                    this.axios.put(apiUrl + '/user/i/' + this.id, person, jwtTokenConfig)
                        .then(response => {
                          if (response.status === 202) {
                            this.myProfile = response.data
                            this.emailExistAlert = false
                          } else {
                            alert("что то пошло не так")
                          }
                          console.log(response)
                        })
                        .catch(function (error) {
                          console.log(error)
                          if (error.response.status === 401) {
                            router.push('/login').then(r => {
                              console.log("error: " + r)
                            })
                          }
                        })

                  }

                })
          }
        }
      },

      GeneratePassword() {
        let text = ""
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

        for (var i = 0; i < 13; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length))

        this.password = text
      },

    },

    created: function () {

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.axios
          .get(apiUrl +'/user/i', jwtTokenConfig)
          .then(res => {
            this.myProfile = res.data
            this.personEmail = res.data.personEmail
            this.personMobilePhone = res.data.personMobilePhone
            this.inHall = res.data.inHall
            this.inOnline = res.data.inOnline

            console.log('myProfile: ', res.data)

          }).catch(error => {
        console.log(error)
        if(error.response.status===401){
          router.push('/login').then(r => {console.log("error: " + r)})
        }

      })

    }
  }
</script>

<style>

  .avatar-cropper-close {
   margin-top: 60px !important;
  }
  .card-img-overlay {
    display: none;
    transition: all 0.5s;
  }
  .card-img-overlay button {
    margin-top: 20vh;
  }
  .card:hover .card-img-overlay {
    display: block;
  }

  .avatar-cropper-close {
    float: right;
    padding: 20px;
    font-size: 3rem;
    color: #263238  !important;
    font-weight: 100;
    text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
  }

  .avatar-cropper-btn {
    width: 50%;
    padding: 15px 0;
    cursor: pointer;
    border: none;
    outline: none;
    color: #263238  !important;
    background-color: #fff  !important;
  }

  .avatar-cropper-btn:hover {
    width: 50%;
    padding: 15px 0;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #263238  !important;
    color: #fff  !important;
  }

</style>
