<template>
  <v-layout style=" box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);">
    <v-app-bar
        prominent
        :class="$vuetify.display.smAndDown? 'small_footer_radius' : 'big_footer_radius'"
    >
      <v-app-bar-nav-icon v-if="loggedIn"  variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <font-awesome-icon icon="fa-solid fa-user-doctor"/>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn @click="makeLogout" v-if="loggedIn" variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"/></v-btn>
      <v-btn @click="loginDialog=true" v-else variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket"/></v-btn>
      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-if="user"
        class="v-navigation-drawer"
        :class="$vuetify.display.smAndDown ? 'big_footer_radius' : 'small_footer_radius'"
        v-model="drawer"
        expand-on-hover
        rail

    >
      <v-list  :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'">
        <v-list-item v-if="((myProfile!==null)&(myProfile.personAvatar!==null))"
                     :prepend-avatar="'/avatar/' + myProfile.personAvatar"
                     :title="myProfile.firstname + ' ' + myProfile.lastname"
                     :subtitle=myProfile.userEmail
        ></v-list-item>
        <v-list-item v-else
                     :title="myProfile.firstname + ' ' + myProfile.lastname"
                     :subtitle=myProfile.userEmail
        ></v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav   :class="$vuetify.display.smAndUp ? 'big_footer_radius' : 'small_footer_radius'">
        <v-list-item prepend-icon="mdi-home" title="Домой"  @click="sidebarItem=0" ></v-list-item>
        <v-list-item prepend-icon="mdi-account-settings" title="Профиль"  @click="sidebarItem=1" v-if="loggedIn===true"></v-list-item>
        <v-list-item prepend-icon="mdi-comment" title="Сообщения" @click="sidebarItem=2" v-if="loggedIn===true"></v-list-item>
        <v-list-item prepend-icon="mdi-folder" title="Файлы" @click="sidebarItem=3" v-if="loggedIn===true"></v-list-item>
        <v-list-item prepend-icon="mdi-screwdriver" title="Администрирование" @click="goToAdminArea" v-if="isTeacher || isTutor || isMaster || isAdmin"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main
        class="align-self-start"
        :class="($vuetify.display.smAndDown)&&(sidebarItem===0) ? 'small_footer_radius_main' : 'big_footer_radius_main'"
    >
      <div
          v-if="Number(sidebarItem)!==0"
          grid-list-md
          text-xs-center
          style="display: flex;
          min-height: 92vh;
          flex-direction: column;
          justify-content: space-between;">
        <v-layout row wrap>
          <v-container class="align-self-start my-10" >
            <ProfileCard v-if="Number(sidebarItem)===1" :user="user" :myProfile="myProfile"></ProfileCard>
            <profile-chat v-if="Number(sidebarItem)===2"></profile-chat>
            <FileCard v-if="Number(sidebarItem)===3"></FileCard>
          </v-container>
        </v-layout>
      </div>
      <div v-else>
        <v-parallax
            :src="$vuetify.display.smAndDown ? require('./static/space _2_grad.jpeg') : require('./static/space_1_grad.jpeg')"
        >
          <div class="d-flex flex-column fill-height justify-center align-center">
            <h4 class="subheading text-lg-h5 text-xs-h8 text-md-h7 font-weight-black ma-6" style="color:#ffffff;" align="center">
              Межрегиональная научно-практическая конференция
            </h4>
            <h1 class="subheading text-lg-h2 text-xs-h7 text-sm-h4 text-md-h3  font-weight-black ma-6 " style="color: #ffffff" align="center">
              «Актуальные вопросы хирургии»
            </h1>
            <h4 class="subheading text-lg-h5 text-xs-h8 text-md-h7 font-weight-black ma-6" style="color:#ffffff;" align="center">
              К 85-летию Иркутской области
            </h4>
            <v-btn
                rounded="pill"
                size="x-large"
                variant="outlined"
                class="ma-8"
                color="#ffffff"
                v-scroll-to="'#action_place'"
            >
              <v-icon color="#ffffff">mdi-chevron-double-down</v-icon>
            </v-btn>
          </div>

        </v-parallax>

        <section id="action_place">
          <div class="py-12"></div>

          <v-container class="text-center">
            <h2 class="display-2 font-weight-bold mb-3">Место проведения</h2>

            <v-responsive
                class="mx-auto mb-8"
                width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>

            <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width="720"
            >
              г. Иркутск, ул. Красного Восстания 3, ИГМУ, Анатомический корпус, Зал ученого совета.

            </v-responsive>
            <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width="720"
            >
              9  декабря 2022 г., Баллы НМО за онлайн-участие не присваиваются
            </v-responsive>

            <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width="720"
            >
              Документация по данному учебному мероприятию представлена в комиссию по оценке учебных мероприятий и материалов НМО
            </v-responsive>

            <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width="720"
            >
              Доступно онлайн-участие. Ссылка на онлайн-конференцию поступит всем  зарегистрированным пользователям за день до начала события.
            </v-responsive>


            <div></div>
            <v-responsive
                class="mx-auto mb-8"
                width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>
            <v-btn
                rounded="pill"
                size="x-large"
                variant="outlined"
                class="ma-8"
                style="color: #42A5F5"
                v-scroll-to="'#tezises'"
            >
                   <span class="grey--text text--darken-1 font-weight-bold">
              Доклады
            </span>
            </v-btn>

          </v-container>

          <div class="py-12"></div>
        </section>

        <section id="tezises">
          <div class="d-flex fill-height justify-center align-center"></div>

          <v-container class="text-center">
            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Доклады</h2>
            <v-responsive
                class="mx-auto mb-12"
                width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>


          </v-container>
          <v-row class="d-flex justify-center" >
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"
            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Нежелательные явления в неотложной хирургии</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify px-6"
              >
                <b>Сандаков П.И.</b>, к.м.н., доцент, главный внештатный хирург министерства здравоохранения Иркутской области.
                Кафедра госпитальной хирургии , Иркутский государственный  медицинский университет Министерство здравоохранения Иркутской области
              </div>



            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"
            >
              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Современные возможности лабораторной диагностики хирургической инфекции</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              ><b>Белохвостикова Т.С.</b>, д.м.н., профессор. ЛиКЛД ИГМАПО - филиал РИМАПО, меддиректор Юнилаб
              </div>
            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"

            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"


              >  <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Особенности анестезиологического обеспечения операций в гериатрии</h3>

              <div
                  cols="12" md="3"  sm="12" xl="3" lg="3"
                  class="text-center ma-2"
              >
                <b>Маньков А.В.</b>,  к.м.н., доцент, заведующий кафедрой анастезиологии и реаниматологии ФГБОУ ВО ИГМУ минздрава РФ
              </div>



            </v-col>

            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"
            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>

              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Казуистика в эндокринной хирургии</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Белобородов В.А.</b>,  д.м.н.,  профессор, заведующий кафедры общей хирургии фгБОУ ВО ИГМУ Минздрава РФ.
                <b>Маньковский В.А.</b>, врач хирург, онколог,
                <b>Еселевич О.В.</b>, врач хирург, онколог,
                <b>Бобкова О.В.</b>, стоматолог хирург, Красноярская краевая клиническая больница.
                <b></b>
              </div>



            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"
            >
              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Одномоментная двухсторонняя ретрограднаялитотрипсия конкрементов почек с использованием одноразового гибкого уретроэндоскопа</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Воробьев В.А.</b>, к.м.н., доцент,
                <b>Белобородов В.А.</b>,  д.м.н.,  профессор, заведующий кафедры общей хирургии ФГБОУ ВО ИГМУ Минздрава РФ.
                <b></b>
              </div>
            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"

            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Травма портальных тубулярных структур в результате холецистэктомии. Организационные, тактические и технические проблемы.</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Мовсиян М.О.</b>, ассистент,
                <b>Новожилов А.В.</b>, к.м.н., доцент,
                <b>Григорьев С.Е.</b>, к.м.н., доцент,
                <b>Сандаков П.И.</b>, к.м.н., доцент,
                Кафедра госпитальной хирургии ФГБОУ ВО ИГМУ Минздрава РФ.
                <b></b>
              </div>



            </v-col>

            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"
            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3 class="font-weight-black mb-4 text-uppercase">
                Трансплантация печени после повреждения портальных тубулярных структур и нижней полой вены при холецистэктомии.
                Ретрансплантация на фоне декомпенсированного геморрагического шока, абдоминального сепсиса, мультиорганной дисфункции.
              </h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Новожилов А.В.</b>, к.м.н., доцент,
                <b>Григорьев С.Е.</b>, к.м.н., доцент,
                <b>Иноземцев Е.О.</b>, к.м.н.,
                <b>Савосин Д.В.</b>, врач-хирург,
                <b>Щербаков Р.И.</b>, врач-хирург
                Иркутская областная клиническая больница.
              </div>



            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"
            >
              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
            </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Лечение пилонидальных кист у подростков: метод Gips</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Новожилов В.А.</b>, д.м.н., профессор, заведующий кафедрой детской хирургии ФГБОУ ВО ИГМУ Минздрава РФ,
                <b>Звонков Д.А., Петров Е.М., Мороз С.В., Латыпов В.Х., Кунц М.Л., Мочалов М.Н., Михайлов Н.И.</b> - врачи детские хирурги городской Ивано-Матренинской детской клинической больницы, г. Иркутск.
                <b>Степанова Н.М., Милюкова Л.П.</b>, доценты кафедры детской хирургии ФГБОУ ВО ИГМУ Минздрава РФ,
              </div>
            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"

            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"


              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Этапное лечение ножевого ранения селезенки, поджелудочной железы, левой почки</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Иноземцев Е.О.</b>, к.м.н., ассистент кафедры госпитальной хирургии ФГБОУ ВО ИГМУ Минздрава РФ,
                <b>Панасюк А.И.</b>, ассистент ассистент кафедры госпитальной хирургии ФГБОУ ВО ИГМУ Минздрава РФ.
              </div>



            </v-col>

            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"
            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >  <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Лечение осложненного колоректального рака</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Медведников  А.А.</b>, заведующий отделением,
                <b>Пленкин С.М., Захаров А.Г., Николаева Н.А., Радостев С.И., Литвинцев А.А.</b>, врачи хирурги отделения колопроктологии Иркутского онкологического диспансера.
                <b>Шелехов А.В.</b>, д.м.н., профессор  ФГБОУ ВО ИГМУ Минздрава РФ.
              </div>



            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"
            >
              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"

              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Редкий случай обтурационной кишечной непроходимости: тонко-толстокишечная инвагинация у взрослого. Клиническое наблюдение.</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Белобородов В.А.</b>,  д.м.н.,  профессор, заведующий кафедры общей хирургии ФГБОУ ВО ИГМУ Минздрава РФ,
                <b>Кожевников М.А.</b>,  к.м.н.,  ассистент  кафедры общей хирургии ФГБОУ ВО ИГМУ Минздрава РФ,
                <b>Томаш А.П.</b>,  заместитель главного врача по хирургии Иркутской городской клинической больницы № 1.
              </div>
            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"

            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"


              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Особенности кишечной непроходимости, вызванной билиарным конкрементом. Диагностика и хирургиеское лечение.</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Белобородов В.А.</b>,  д.м.н.,  профессор, заведующий кафедры общей хирургии ФГБОУ ВО ИГМУ Минздрава РФ,
                <b>Цоктоев Д.Б.</b>,  к.м.н.,  ассистент кафедры общей хирургии ФГБОУ ВО ИГМУ Минздрава РФ,
                <b>Томаш А.П.</b>,  заместитель главного врача по хирургии Иркутской городской клинической больницы № 1,
                <b>Мащенко А.С., Цимпилова Б.Ц.</b>,  врачи-хирурги Иркутской городской клинической больницы № 1,

              </div>



            </v-col>
            <v-col
                cols="12" md="3"  sm="12" xl="3" lg="3"
                class="text-center ma-2"

            >

              <v-avatar
                  class="elevation-12 mb-12 "
            :class="theme.global.current.value.dark ? 'card_dark_header' : 'card_ligth_header'"
            size="128"


              >
                 <font-awesome-icon icon="fa-solid fa-user-doctor"  class="fa-4x"/>
              </v-avatar>

              <h3
                  class="font-weight-black mb-4 text-uppercase"
              >Грыжа Морганьи-Ларрея у взрослого</h3>

              <div
                  class="title font-weight-light mb-5
                       text-justify  px-6"
              >
                <b>Махутов В.Н.</b>,  к.м.н., заведующий кафедры отделением торакальной хирургии Иркутской областной клинической больницы,
                <b>Шевченко Д.А.</b>,  врач-хирург Иркутской областной клинической больницы,
                <b>Лебедева Д.В.</b>,  ассистент кафедры госпитальной хирургии ФГБОУ ВО ИГМУ Минздрава РФ.

              </div>



            </v-col>


          </v-row>

          <v-responsive
              class="mx-auto mb-8"
              width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>
          <div class="d-flex fill-height justify-center align-center">
            <v-btn
                rounded="pill"
                size="x-large"
                variant="outlined"
                class="ma-8"
                style="color: #42A5F5"
                v-scroll-to="'#join'"
            >
                   <span class="grey--text text--darken-1 font-weight-bold">
              Принять участие
            </span>
            </v-btn>
          </div>
          <div class="py-12"></div>


        </section>


        <section id="join">
        <v-sheet
            id="contact"
            color="primary"
            tag="section"
            tile
        >
          <div class="py-12"></div>

          <v-container>
            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Регистрация участника</h2>

            <v-responsive
                class="mx-auto mb-12"
                width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>
            <v-form ref="regForm" lazy-validation>
            <v-theme-provider>
              <v-row>
                <v-col cols="12" md="4"  sm="12" xl="4" lg="4" >
                  <v-text-field
                      flat
                      label="Фамилия*"
                      solo
                      v-model="lastname"
                      :rules="lastnameRules"
                     requred
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4"  sm="12" xl="4" lg="4" >
                  <v-text-field
                      flat
                      label="Имя*"
                      solo
                      v-model="firstname"
                      :rules="firstnameRules"
                      requred
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4"  sm="12" xl="4" lg="4" >
                  <v-text-field

                      label="Отчество*"
                      v-model="secname"
                      :rules="secnameRules"

                      requred
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      flat
                      label="Организация*"
                      solo
                      v-model="personOrganization"
                      requred
                      :rules="personOrganizationRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      flat
                      label="Подразделение*"
                      solo
                      v-model="personOrganizationDepartment"
                      requred
                      :rules="personOrganizationRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Почтовый адрес организации(с указанием страны, субъекта)*"
                      v-model="personOrganizationPostAddress"
                      :rules="personOrganizationPostAddressRules"
                      requred
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      flat
                      label="Занимаемая должность*"
                      solo
                      v-model="personOrganizationPosition"
                      requred
                      :rules="personOrganizationRules"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field

                      label="Специальность основная*"
                      v-model="personSpeciality"
                      :rules="personSpecialityRules"
                      requred
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      label="Специальность дополнительная*"
                      v-model="personSpecialityDop"
                      :rules="personSpecialityDopRules"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      label="Стаж по основной специальности*"
                      v-model="personSpecialityExp"
                      :rules="personSpecialityExpRules"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      label="Email*"
                      v-model="personEmail"
                      :rules="emailRules"
                      requred
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      label="Телефон*"
                      v-model="personMobilePhone"
                      :rules="personMobilePhoneRules"
                      requred
                  ></v-text-field>
                </v-col>



                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      label="Пароль(не менее 10 символов)*"
                      v-model="password"
                      :rules="passwordRules"
                      type="password"
                      requred

                  ></v-text-field>
                </v-col>



                <v-col cols="12" md="6"  sm="12" xl="6" lg="6">
                  <v-text-field
                      label="Подтверждение пароля*"
                      v-model="passwordConfirm"
                      :rules="passwordRules"
                      type="password"
                      requred
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-switch
                      v-model="inHall"
                      variant="outlined"
                      label="Очное участие"

                  ></v-switch>
                </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-switch
                        v-model="inOnline"
                        variant="outlined"
                        label="Онлайн участие"

                    ></v-switch>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                  <v-switch
                      v-model="pdAgree"
                      variant="outlined"
                      label="Даю согласие на обработку ПД"
                      requred
                      :rules="pdAgreeRules"

                  ></v-switch>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-switch
                      v-model="uAgree"
                      variant="outlined"
                      label="Принимаю пользовательское соглашение"
                      requred
                      :rules="uAgreeRules"
                  ></v-switch>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="auto"
                >
                  <v-btn  class="my-2 w-100"
                          color="white"
                          variant="outlined"
                          rounded="pill"
                          v-on:click="singUp"
                  >
                    Зарегистрироваться
                  </v-btn>
                </v-col>
              </v-row>
            </v-theme-provider>
            </v-form>
          </v-container>

          <div class="py-12"></div>
        </v-sheet>
        </section>

        <v-dialog
            v-model="loginDialog"
            persistent
        >
          <v-card >
            <v-card-title class="card_ligth_header">
              <span class="text-h5">Вход в систему</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="loginForm" lazy-validation>
                <v-text-field
                    name="username"
                    label="Имя пользователя"
                    :rules="usernameRules"
                    v-model="username"
                    hide-details="auto"
                    outlined
                    aria-required="true"
                    class="ma-2"
                    style="min-width: 350px; max-width: 350px;"
                >
                </v-text-field>
                <v-text-field
                    id="password"
                    name="password"
                    label="Пароль"
                    type="password"
                    :rules="passwordRules"
                    v-model="password"
                    outlined
                    aria-required="true"
                    class="ma-2"
                    style="min-width: 350px; max-width: 350px;"
                ></v-text-field>
                <v-alert
                    density="compact"
                    style="min-width: 350px; max-width: 350px;"
                    :type="alert.type"
                    v-if="alert.type!=null"
                    class="ma-2"
                >
                  {{ alert.message }}
                </v-alert>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions">
              <v-spacer></v-spacer>
              <v-btn
                  v-if="alert.type!=null"
                  variant="outlined"
                  rounded
                  text
                  @click="showReqDialog"
              >
                Сброс пароля
              </v-btn>
              <v-btn
                  variant="outlined"
                  rounded
                  text
                  @click="closeLoginDialog"
              >
                Отмена
              </v-btn>
              <v-btn
                  variant="outlined"
                  rounded
                  :disabled="loggingIn"
                  @click="makeLogin"
              >
                Войти
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="passwordReqDialog"
            persistent
        >
          <v-card >
            <v-card-title class="card_ligth_header">
              <span class="text-h5">Сброс пароля</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="resetForm" lazy-validation>
                <v-text-field
                    name="username"
                    v-show="reqSuccess===false"
                    label="Ваш email"
                    :rules="emailRules"
                    v-model="passwordReqEmail"
                    hide-details="auto"
                    outlined
                    aria-required="true"
                    class="ma-2"
                    style="min-width: 350px; max-width: 350px;"
                >
                </v-text-field>
                <v-col
                    cols="12"
                    v-show="reqSuccess===false"
                >
                  <v-switch
                      v-model="passwordReqConfirm"
                      variant="outlined"
                      requred

                      :rules="passwordReqConfirmRules"
                      label="Подтверждаю запрос на сброс пароля"

                  ></v-switch>
                </v-col>

                <v-alert
                    density="compact"
                    style="min-width: 350px; max-width: 350px;"
                    v-if="confirmStatus"
                    class="ma-2"
                >
                  {{ confirmBody }}
                </v-alert>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions">
              <v-spacer></v-spacer>

              <v-btn
                  variant="outlined"
                  rounded
                  text
                  @click="closeResetDialog"
              >
                Закрыть
              </v-btn>
              <v-btn
                  v-if="!reqSuccess"
                  variant="outlined"
                  rounded
                  @click="makeReset"
              >
                Отправить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
<div style="display: flex;
      flex-direction: column;
      " class="align-self-start">
  <site-footer ></site-footer>
</div>


    </v-main>


  </v-layout>
</template>

<script>
import {router} from "@/service/router"
import ProfileCard from '@/components/Profile/ProfileCard.vue'
import FileCard from '@/components/Profile/FileCard'
import ProfileChat from "@/components/Profile/ProfileChat"
import SiteFooter from "@/components/sections/SiteFooter";


import { useTheme } from 'vuetify'
import {apiUrl} from "@/service/user.service";




export default {
  name: 'HomePage',
  setup () {
    const theme = useTheme()

    if (localStorage.colorTheme) {
      console.log("theme exist :" + localStorage.colorTheme)
      theme.global.name.value = localStorage.colorTheme
    } else {
      theme.global.name.value = 'myCustomLightTheme'
    }

    return {
      theme,
      toggleTheme: () => {

        if(theme.global.current.value.dark) {
          localStorage.colorTheme = 'myCustomLightTheme'
          theme.global.name.value = 'myCustomLightTheme'
          console.log("set ligth")
        } else {
          localStorage.colorTheme = 'dark'
          theme.global.name.value = 'dark'
          console.log("set dark")
        }
      }
    }
  },
  components: {
    ProfileCard,
    FileCard,
    SiteFooter,
    ProfileChat,
  },
  watch: {
    sidebarItem(newSidebarItem) {
      console.log("newSidebarItem: " + newSidebarItem)
      if(localStorage.ProfileSidebarItem !== newSidebarItem){
        localStorage.ProfileSidebarItem = newSidebarItem;
      }
    },

    loggedIn(newLoggedIn){
      if(newLoggedIn){
        this.loginDialog=false
        console.log("User successful login")
        location.reload()
      } else {
        console.log("User not login")
      }
    },

  },
  mounted() {
    if (localStorage.ProfileSidebarItem) {
      this.sidebarItem = JSON.parse(localStorage.ProfileSidebarItem)
    } else {
      this.sidebarItem=0
    }
  },
  data () {
    return {
      personOrganizationDepartment:'',
      personSpecialityDop:'',
      personSpecialityExp:'',
      inHall:false,
      inOnline:false,
      passwordReqDialog:false,
      passwordReqConfirm: false,
      passwordReqEmail: '',
      confirmBody: '',
      confirmStatus:false,
      personEmail:'',
      firstname:'',
      secname:'',
      lastname:'',
      personOrganization:'',
      personOrganizationPostAddress:'',
      personSpeciality:'',
      personMobilePhone:'',
      passwordConfirm:'',
      personOrganizationPosition:'',


      drawer: false,
      sidebarItem: 0,
      pdAgree:false,
      uAgree:false,
      loginDialog:false,
      reqSuccess:false,
      usernameRules: [
        v => !!v || 'Укажите имя пользователя!',
      ],
      lastnameRules: [
        v => !!v || 'Укажите фамилию!',
        v => (v && v.length < 50) || 'не более 50 символов',
      ],
      firstnameRules: [
        v => !!v || 'Укажите имя!',
        value => (value && value.length < 50) || 'не более 50 символов',
      ],
      secnameRules: [
        v => !!v || 'Укажите отчество!',
        value => (value && value.length < 50) || 'не более 50 символов',
      ],
      personOrganizationRules: [
        v => !!v || 'Укажите организацию!',
        value => (value && value.length < 250) || 'не более 250 символов',
      ],
      personSpecialityRules: [
        v => !!v || 'Укажите специальность!',
        value => (value && value.length < 250) || 'не более 250 символов',
      ],
      personSpecialityDopRules: [
        value => (value && value.length < 250) || 'не более 250 символов',
      ],
      personSpecialityExpRules: [
        value => (value && value.length < 250) || 'не более 250 символов',
      ],
      emailRules: [
        v => !!v || 'Укажите адрес электронной почты!!',
        value => (value && value.length < 50) || 'не более 50 символов',
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail не валидный'

      ],
      personMobilePhoneRules: [
        v => !!v || 'Укажите контактный телефон!!',
        value => (value && value.length < 50) || 'не более 50 символов',
      ],
      personOrganizationPostAddressRules: [
        v => !!v || 'Укажите контактный телефон!!',
        value => (value && value.length < 250) || 'не более 250 символов',
      ],
      passwordRules: [
        v => !!v || 'Укажите пароль!',
        value => (value && value.length >= 10) || 'Не менее 10 символов!',
      ],
      pdAgreeRules: [
        v => !!v  || 'Необходимо дать согласие на обработку персональных данных!',

      ],
      uAgreeRules: [
        v => !!v || 'Необходимо принять пользовательское соглашение!',

      ],
      passwordReqConfirmRules: [
        v => !!v  || 'Необходимо дать согласие на сброс пароля!',

      ],
      username: '',
      password: '',
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 10) || 'Min 10 characters',
      ],
      myProfile: {},

      menu: [
        { text: 'Главная', link: '/' },
        { text: 'Пользовательское соглашение', link: '/user_agreement' },
        { text: 'Политика обработки ПД', link: '/personal_data_politic' },
      ],
      passwordMismatch:false,
    }
  },
  computed: {

    loggingIn () {
      return this.$store.state.authentication.status.loggingIn
    },
    isParent() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_PARENT')
      } else return false

    },
    isChild() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_CHILD')
      } else return false

    },
    isTeacher() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TEACHER')
      } else return false

    },
    isTutor() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TUTOR')
      } else return false
    },
    isAdmin() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_ADMIN')
      } else return false
    },
    isMaster() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_MASTER')
      } else return false
    },
    loggedIn () {

      return this.$store.state.authentication.status.loggedIn
    },
    user () {
      return this.$store.state.authentication.user
    },
    authentication () {
      return this.$store.state.authentication
    },
    alert () {
      console.log('this.$store.state.alert' + this.$store.state.alert)
      return this.$store.state.alert

    }

  },

  methods: {
    showReqDialog(){
      this.loginDialog = false
      this.passwordReqDialog =true
    },

    makeReset(){
      this.$refs.resetForm.validate().then(responce=>{
            if(responce.valid){
             let passwordReq = {
               userEmailForRequest: this.passwordReqEmail

              }
              this.axios.post(apiUrl +'/auth/password_req', passwordReq)
                  .then(response => {
                    if (response.status === 202) {

                      this.$refs.regForm.reset()
                      this.passwordReqEmail = ''
                      this.passwordReqConfirm = false
                      this.confirmStatus = true
                      this.reqSuccess = true
                      this.confirmBody = 'Ссылка для сброса пароля отправленна на Ваш  Email'

                    } else {
                      this.passwordReqEmail = ''
                      this.passwordReqConfirm = false
                      this.confirmStatus = true
                      this.reqSuccess = false
                      this.confirmBody = 'Не корректный Email'

                    }
                    console.log(response)
                  })
                  .catch(function (error) {
                    console.log(error)
                    if (error.code==="ERR_NETWORK") {
                      alert("Сервер не доступен! Проверьте свое подключение и попробуйте нажать кнопку еще раз!");
                    } else {
                      if(error.response.status===401){
                        router.push('/login').then(r => {console.log("error: " + r)})
                      }
                      if(error.response.status===400){
                        alert("Email уже зарегистрирован в системе!")
                      }
                    }
                  })

            }})

    },
    closeResetDialog(){
      this.passwordReqEmail = ''
      this.passwordReqConfirm = false
      this.confirmStatus = false
      this.confirmBody = ''
      this.reqSuccess = false
      this.passwordReqDialog =false
      this.loginDialog = false

    },
    goToAnket(){
      router.push('/questionnaire_survey').then(r => {console.log("error: " + r)})

    },
    singUp(){
      this.$refs.regForm.validate().then(responce=>{
        if(responce.valid){
          if(this.password===this.passwordConfirm){
            this.passwordMismatch=false
            let signUpData ={
              firstname: capitalizeFirstLetter(this.firstname),
              secname: capitalizeFirstLetter(this.secname),
              lastname: capitalizeFirstLetter(this.lastname),
              personEmail: this.personEmail,
              personOrganization: capitalizeFirstLetter(this.personOrganization),
              personOrganizationPostAddress: capitalizeFirstLetter(this.personOrganizationPostAddress),
              personSpeciality: capitalizeFirstLetter(this.personSpeciality),
              personMobilePhone: this.personMobilePhone,
              passwordConfirm: this.passwordConfirm,
              password: this.password,
              inHall: this.inHall,
              inOnline: this.inOnline,
              personOrganizationDepartment: this.personOrganizationDepartment,
              personSpecialityDop: this.personSpecialityDop,
              personSpecialityExp: this.personSpecialityExp,

            }
            this.axios.post(apiUrl +'/auth/signup', signUpData)
                .then(response => {
                  if (response.status === 201) {
                    alert("Вы успешно зарегистрировались!")
                    this.$refs.regForm.reset()
                    this.pdAgree = false
                    this.uAgree = false
                    this.inHall = false


                  } else {
                    alert("что то пошло не так")
                  }
                  console.log(response)
                })
                .catch(function (error) {
                  console.log(error)
                  if (error.code==="ERR_NETWORK") {
                    alert("Сервер не доступен! Проверьте свое подключение и попробуйте нажать кнопку еще раз!");
                  } else {
                    if(error.response.status===401){
                      router.push('/login').then(r => {console.log("error: " + r)})
                    }
                    if(error.response.status===400){
                      alert("Email уже зарегистрирован в системе!")
                    }
                  }
                })
          } else {
            this.passwordMismatch=true
          }


        } else {
          alert("Проверьте корректность заполнения полей!")
        }
      })
    },
    goToInternalUse(){
      router.push('/internal_use').then(r => {console.log("error: " + r)})
    },

    goToProfile () {
      router.push('/pk').then(r => {console.log("error: " + r)})
    },
    closeLoginDialog(){
      this.$store.dispatch('alert/clear')
      this.password=''
      this.username=''
      this.loginDialog=false
    },
    goToWebinarAdminArea () {
      router.push('/webinar_admin_area').then(r => {console.log("error: " + r)})
    },

    makeLogin () {
        this.$refs.loginForm.validate().then(responce => {
          if (responce.valid){
            const { username, password } = this
            const { dispatch } = this.$store
            dispatch('authentication/login', { username, password })
            console.log("this.authentication.status: " + this.authentication.status)

          }
        }).then(result => {
          console.log("result: " + result)
          if(this.authentication.status.loggedIn===true){
            this.loginDialog= false
          }
        } )


    },
      makeLogout () {
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        this.sidebarItem=0

      },

    goToAdminArea() {
      router.push('/admin').then(r => {console.log("error: " + r)})

    }
  },
  created: function () {

    if(this.user!==null){
      let jwtTokenConfig = {
        headers: {
        Authorization: "Bearer " + this.$store.state.authentication.user.token
      }
    }
    this.axios
      .get(apiUrl +'/user/i', jwtTokenConfig)
      .then(res => {
        this.myProfile = res.data
        console.log('myProfile: ', res.data)

      }).catch(error => {
    console.log(error)
    if(error.response.status===401){
      router.push('/').then(r => {console.log("error: " + r)})
    }

  })
} else {
      this.sidebarItem=0
    }
  }
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


</script>
<style>
#app {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
body {
  font-family: "Montserrat";
}
.title {
  font-size: 1.25rem!important;
  font-weight: 500;
  letter-spacing: .0125em!important;
}
.big_footer_radius {
  border-bottom-left-radius:20px;
  border-bottom-right-radius: 20px;
  opacity: .85;
  background-color: #ffffff!important;
  color: #1E88E5 !important;
}
.big_footer_radius_main {
  margin-top: -65px;
}

.small_footer_radius {
  border-bottom-left-radius:5px;
  border-bottom-right-radius: 5px;
  opacity: .9;
  background-color: #ffffff!important;
  color: #1E88E5 !important;
}
.small_footer_radius_main {
  margin-top: -5px;

}
.card_ligth_header {

  background-color: #ffffff!important;
  color: #1E88E5 !important;

}
.card_dark_header {

  background-color: #1E88E5!important;
  color: #ffffff !important;

}
.card_ligth_actions {

  background-color: #ffffff!important;
  color: #1E88E5 !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.v-card{
  box-shadow: 0 0 2px 2px rgba(221, 221, 221, 1);
  opacity: .95;

}

</style>