<template>
  <v-container  >
    <v-card class="mx-auto ma-10">
      <v-tabs v-model="tab" background-color="primary" >
        <v-tab value="1">Мои документы</v-tab>
        <v-tab value="2">Общий доступ</v-tab>
      </v-tabs>
      <v-card-text class="align-self-stretch h-75 ma-0 pa-0">
        <v-window v-model="tab" class="align-self-stretch" >
          <v-window-item value="1" class="align-self-stretch">
            <v-card-title class="card_ligth_header">
              <h5>Мои документы</h5>
            </v-card-title>
            <v-card-title class="card_ligth_header">
              <v-row >
                <v-col cols=12>
                  <v-text-field
                      v-model="searchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                      append-icon="mdi-file-plus"
                      @click:append="showFileInput=true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="align-self-stretch h-100">
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="(item) in paginatedData" :key="item.id">
                  <v-card-title class="card_ligth_header">
                    <h5>Файл # {{filteredFiles.indexOf(item) +1}}</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text >
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{ item.fileName }}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{ item.fileDescrption }}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Добавлен:</v-list-item-title>
                        <v-list-item-subtitle>{{(item.createDateTime).replace('T', ' ')}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Совместный доступ:</v-list-item-title>
                        <v-list-item-subtitle> <v-chip-group active-class="primary--text" column v-if="item.personGroups.length>0">
                          <v-chip size="small" v-for="group in item.personGroups" :key="group" >
                            {{group.personGroupName}}
                          </v-chip>
                        </v-chip-group>
                          <v-chip-group active-class="primary--text" column v-else>
                            <v-chip size="small" color="success">
                              нет
                            </v-chip>
                          </v-chip-group></v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="card_ligth_actions">
                    <v-btn @click="downloadFile(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-download" /></v-btn>
                    <v-btn @click="deleteFile(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                  </v-card-actions>
                </v-card>
              </div>
              <div class="d-none d-sm-block bg-success">
                <v-table fixed-header  >
                  <thead >
                  <tr >
                    <th class="text-left  ">#</th>
                    <th class="text-left ">Файл</th>
                    <th class="text-left hidden-md-and-down">Описание</th>
                    <th class="text-left hidden-md-and-down">Добавлен</th>
                    <th class="text-left ">Совместный доступ</th>
                    <th class="text-left">Действие </th>

                  </tr>
                  </thead>
                  <tbody >
                  <tr v-for="item in paginatedData" :key="item.title">
                    <td class="text-black-50">{{filteredFiles.indexOf(item) +1}}</td>
                    <td>{{ item.fileName }}</td>
                    <td class="hidden-md-and-down">{{ item.fileDescrption }}</td>
                    <td class="hidden-md-and-down">{{(item.createDateTime).replace('T', ' ')}}</td>
                    <td>
                      <v-chip-group active-class="primary--text" column v-if="item.personGroups.length>0">
                        <v-chip size="small" v-for="group in item.personGroups" :key="group">
                          {{group.personGroupName}}
                        </v-chip>
                      </v-chip-group>
                      <v-chip-group active-class="primary--text" column v-else>
                        <v-chip size="small">
                          нет
                        </v-chip>
                      </v-chip-group>
                    </td>
                    <td>
                      <v-btn @click="downloadFile(item)" style="margin: 5px;" size="small" variant="outlined" color="accent" rounded><font-awesome-icon icon="fa-solid fa-download" /></v-btn>
                      <v-btn @click="deleteFile(item)" style="margin: 5px;" size="small" variant="outlined" color="warning" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions">
              <v-container class="text-center">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="3"
                    size="small">
                </v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
          <v-window-item value="2" class="align-self-stretch">
            <v-card-title class="card_ligth_header">
              <h5>Общий доступ</h5>
            </v-card-title>
            <v-card-title class="card_ligth_header">
              <v-row >
                <v-col cols=12>
                  <v-text-field
                      v-model="sharedFileSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="align-self-stretch h-100">
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="(item) in sharedFilesPaginatedData" :key="item.id">
                  <v-card-title class="card_ligth_header">
                    <h5>Файл # {{filteredSharedFiles.indexOf(item) +1}}</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text >
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{ item.fileName }}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{ item.fileDescrption }}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Добавлен:</v-list-item-title>
                        <v-list-item-subtitle>{{(item.createDateTime).replace('T', ' ')}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Совместный доступ:</v-list-item-title>
                        <v-list-item-subtitle> <v-chip-group active-class="primary--text" column v-if="item.personGroups.length>0">
                          <v-chip size="small" v-for="group in item.personGroups" :key="group" >
                            {{group.personGroupName}}
                          </v-chip>
                        </v-chip-group>
                          <v-chip-group active-class="primary--text" column v-else>
                            <v-chip size="small" color="success">
                              нет
                            </v-chip>
                          </v-chip-group></v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="card_ligth_actions">
                    <v-btn @click="downloadFile(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-download" /></v-btn>
                    <v-btn @click="deleteFile(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                  </v-card-actions>
                </v-card>
              </div>
              <div class="d-none d-sm-block bg-success">
                <v-table fixed-header  >
                  <thead >
                  <tr >
                    <th class="text-left  ">#</th>
                    <th class="text-left ">Файл</th>
                    <th class="text-left hidden-md-and-down">Описание</th>
                    <th class="text-left hidden-md-and-down">Добавлен</th>
                    <th class="text-left ">Создатель</th>
                    <th class="text-left">Действие </th>

                  </tr>
                  </thead>
                  <tbody >
                  <tr v-for="item in sharedFilesPaginatedData" :key="item.title">
                    <td class="text-black-50">{{filteredSharedFiles.indexOf(item) +1}}</td>
                    <td>{{ item.fileName }}</td>
                    <td class="hidden-md-and-down">{{ item.fileDescrption }}</td>
                    <td class="hidden-md-and-down">{{(item.createDateTime).replace('T', ' ')}}</td>
                    <td>
                      <v-chip-group active-class="primary--text" column>
                        <v-chip size="small">
                          нет
                        </v-chip>
                      </v-chip-group>
                    </td>
                    <td>
                      <v-btn @click="downloadFile(item)" style="margin: 5px;" size="small" variant="outlined" color="accent"><font-awesome-icon icon="fa-solid fa-download" /></v-btn>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions">
              <v-container class="text-center">
                <v-pagination
                    v-model="sharedFilePage"
                    :length="sharedFilesPageCount"
                    :total-visible="3"
                    size="small">
                </v-pagination>
              </v-container>
            </v-card-actions>

          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="showFileInput"
        persistent
        scrollable
    >
      <v-form
          ref="form"
          v-model="valid"
      >
        <v-card   max-width="500"
                  min-width="350"
        >
          <v-card-title class="card_ligth_header">
            <h5>Загрузка файла</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                      show-size
                      v-model="fileBody"
                      ref="fileBody"
                      :rules="fileRules"
                      accept="image/jpeg,image/gif, image/jpg, image/png,application/pdf , .doc, .docx, .bmp, .xls, .odt, image/x-eps"
                      clearable
                      variant="outlined"
                      counter
                      rounded
                      v-on:change="handleFileUpload"

                      label="Загрузить файл*"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                      v-model="fileDescrption"
                      variant="outlined"
                      rounded
                      :counter="255"
                      :rules="fileDescrptionRules"
                      label="Описание файла*"
                      required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-autocomplete
                    v-model="personGroups"
                    :items="userGroups"
                    :custom-filter="userGroupFilter"
                    outlined
                    filled
                    chips
                    small-chips
                    closable-chips
                    label="Группы пользователей"
                    item-title="name"
                    item-value="name"
                    multiple
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.personGroupName"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">

                    <v-list-item

                        v-bind="props"
                        :title="item.raw.personGroupName"
                        :subtitle="item.raw.personGroupDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>


            </v-container>
            <small>*обязательно для заполнения</small>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" class="small" style="margin-left: 5px;"  @click="hideFileInput">
              <font-awesome-icon icon="fas fa-undo-alt"/>
            </v-btn>

            <v-btn rounded variant="outlined" class="small" style="margin-left: 5px;" :disabled="downloadAnimation" @click="saveFile" >
              <font-awesome-icon v-if="downloadAnimation===false" icon="fas fa-floppy-disk"/>
              <v-progress-circular v-if="downloadAnimation"
                                   indeterminate
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import {router} from "@/service/router";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

import download from 'js-file-download';
import {apiUrl} from "@/service/user.service"

export default {
  name: 'FileCard',

  props: [],
  data: () => ({
    fileDescrptionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 255) || 'Значение до должно превышать 255 символов!',
    ],
    fileRules: [
        v => !!v || 'Необходимо прикрепить файл!',
        v =>  v[0].size < 200000000 || 'Размер файла не должен превышать 200 MB!'
        ]
    ,
    page: 1,
    noteCount: 20,
    searchBody: '',
    showFileInput: false,
    loading: false,
    fileName: '',
    fileDescrption: '',
    fileBody: [],
    fileKey: 0,
    files: [],
    userGroups: [],
    personGroups: [],
    randomFileName:'',
    ext:'',
    downloadAnimation: false,
    tab:1,
    sharedFileSearchBody:'',
    sharedFilePage:1,
    sharedFiles:[],
  }),
  methods: {
    userGroupFilter(item, queryText){
      console.log("filter item: "+ item)
      const textPersonGroupName = item.personGroupName.toLowerCase()
      const textPersonGroupDescription = item.personGroupDescription.toLowerCase()

      const searchText = queryText.toLowerCase()

      return textPersonGroupName.indexOf(searchText) > -1 ||
          textPersonGroupDescription.indexOf(searchText) > -1

    },
    downloadFile: function (item) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token
        },
        responseType: 'blob'
      }
      this.axios.get(apiUrl +'/downloadFile/' + item.randomFileName,  config)
            .then(resp => {
              this.downloadAnimation = true
              download(resp.data, item.fileName)
              this.downloadAnimation=false

            });
    },
    handleFileUpload () {
      this.fileName = this.$refs.fileBody.files[0].name;
      this.ext = this.fileName.substr(this.fileName.lastIndexOf('.') + 1)
      this.randomFileName = 'sakhalin_school_files_'+uuidv4() + '.'+this.ext
    },
    clearSearch () {
      this.searchBody=''
    },
    hideFileInput () {
      this.showFileInput=false
      this.fileName = ''
      this.fileBody = []
      this.personGroups = []

    },
    saveFile () {
      this.$refs.form.validate().then(result=>{
        if(result.valid){
          this.downloadAnimation= true
          let jwtTokenConfig = {
            headers: {
              Authorization: "Bearer " + this.user.token
            }
          }

          let formData = new FormData();
          this.ext = this.fileName.substr(this.fileName.lastIndexOf('.') + 1);

          formData.append('file', this.$refs.fileBody.files[0], this.randomFileName);

          this.axios.post(apiUrl +'/upload/',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': "Bearer " + this.user.token
                }
              }
               ).then(res => {
                console.log("res: " + {res});
                 let file = {
                    fileName: this.fileName,
                    fileDescrption: capitalizeFirstLetter(this.fileDescrption),
                    fileType: this.ext,
                    randomFileName: this.randomFileName,
                    fileSize: this.$refs.fileBody.files[0].size,
                    personGroups: this.personGroups,
                }



            this.axios.post(apiUrl +'/file', file, jwtTokenConfig)
                .then(response => {
                  if (response.status === 201) {
                    this.files.push(response.data)
                    this.downloadAnimation= false
                    this.showFileInput=false
                    this.fileName = ''
                    this.fileDescrption = ''
                    this.randomFileName = ''
                    this.fileBody = []
                    this.personGroups = []
                  } else {
                    alert("что то пошло не так")
                    this.downloadAnimation= false
                  }
                  console.log(response)
                })
                .catch(function (error) {
                  console.log(error);
                  this.downloadAnimation= false
                })



          }).catch(function () {
            console.log('Ошибка загрузки файла');
            alert("Ошибка загрузки файла, попробуйте еще раз!")
          });
        }
      })
    },
    deleteFile (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      console.log("Item id: " + item.id)
      this.axios
          .delete(apiUrl +'/file/' + item.id, jwtTokenConfig)
          .then(response => {
            if (response.status === 202) {
              this.files.splice(this.files.indexOf(item), 1)
              console.log("Файл успешно удален")
            } else if(response.status === 400){
              console.log("Не удалось удалить файл с диска!")
            } else if(response.status === 403){
              console.log("У Вас нет доступа для удаления данного файла")
            }
            console.log(response)
          })
          .catch(function (error) {
            if(error.response.status===401){
              this.$store.dispatch('authentication/logout')
              this.$store.state.alert.clear
              this.$store.state.user.clear
              router.push('/').then(r => {console.log("error: " + r)})
            }
          })
    }

  },
  computed: {
    filteredFiles() {
      const ss = this.searchBody.toLowerCase();
      let tempFiles = this.files

      return tempFiles.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },
    pageCount(){
      let l = this.filteredFiles.length,
          s = parseInt(this.noteCount);
      console.log("Page count: " + Math.ceil(l/s))

      return Math.ceil(l/s);
    },
    paginatedData(){
      const start = (this.currentPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.filteredFiles.slice(start, end);
    },
    currentPage (){
      if(this.pageCount < this.page){
        return 1
      } else {
        return this.page
      }
    },



    filteredSharedFiles() {
      const ss = this.sharedFileSearchBody.toLowerCase();
      let tempSharedFiles = this.sharedFiles

      return tempSharedFiles.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },
    sharedFilesPageCount(){
      let l = this.filteredSharedFiles.length,
          s = parseInt(this.noteCount);
      console.log("Page count: " + Math.ceil(l/s))

      return Math.ceil(l/s);
    },
    sharedFilesPaginatedData(){
      const start = (this.sharedFilesCurrentPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.filteredSharedFiles.slice(start, end);
    },
    sharedFilesCurrentPage (){
      if(this.sharedFilesPageCount < this.sharedFilePage){
        return 1
      } else {
        return this.sharedFilePage
      }
    },




    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {

    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.$store.state.authentication.user.token
      }
    }

    function getUserGroups(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/user_group', jwtTokenConfig)
    }

    function getUserFiles(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/file', jwtTokenConfig)
    }

    function getSharedFiles(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/file/shared', jwtTokenConfig)
    }

    Promise.all([
      getUserFiles(jwtTokenConfig, this.axios),
      getUserGroups(jwtTokenConfig, this.axios),
      getSharedFiles(jwtTokenConfig, this.axios),

    ]).then(results => {
          if(results[0].status===200){
            this.files = results[0].data;
            console.log("size files: " + this.files.length)
          } else if (results[0].status===401){
            router.push('/login').then(r => {console.log("error: " + r)})
          }
          if(results[1].status===200){
            this.userGroups = results[1].data;
            console.log("size userGroups: " + this.userGroups.length)
          } else if (results[1].status===401){
            router.push('/login').then(r => {console.log("error: " + r)})
          }
          if(results[2].status===200){
            this.sharedFiles = results[2].data;
            console.log("size sharedFiles: " + this.sharedFiles.length)
          } else if (results[2].status===401){
            router.push('/login').then(r => {console.log("error: " + r)})
          }

        });
  }


}
</script>
<style>
</style>
