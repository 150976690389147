<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    matcher: null
  }),
  methods: {
    async onDark(evt) {
      console.debug('onDark', evt)
      await this.$store.dispatch('setDark', evt.matches)
    }
  },
  async created() {
    this.matcher = window.matchMedia('(prefers-color-scheme: dark)')
    // set the initial state from the matcher  await this.onDark(this.matcher)
    this.matcher.addListener(this.onDark)
  }
}
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
</style>

