import 'vuetify/lib/styles/main.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {router} from "@/service/router"
import { createVuetify} from 'vuetify'
import { createApp } from 'vue'
import VueScrollTo from 'vue-scrollto'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { store } from './store/index'
import App from './App'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import lodash from 'lodash'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import AvatarCropper from 'vue-avatar-cropper'
import "@fontsource/montserrat";





//import colors from 'vuetify/lib/util/colors'
import {faUserDoctor, faEnvelope, faChartLine, faUsersGear, faComments, faCalendar, faClose, faBackward, faImage, faArrowLeft, faCheck, faCircleQuestion, faUndo, faBriefcase, faBroom, faFilter, faClone,  faSort, faFileImport, faUserGraduate, faChalkboardTeacher, faPlay, faVideo, faScrewdriverWrench, faAngleRight, faAngleLeft, faServer, faPenToSquare, faArrowRightToBracket, faArrowRightFromBracket, faDownload, faSave, faUndoAlt, faTools, faPlusCircle, faTrashAlt, faXmark, faFloppyDisk, faIdCard, faFolderOpen, faChildren, faMessage, faEllipsisVertical, faAtom } from '@fortawesome/free-solid-svg-icons'
library.add(faUserDoctor)
library.add(faEnvelope)
library.add(faChartLine)
library.add(faUsersGear)
library.add(faCalendar)
library.add(faClose)
library.add(faBackward)
library.add(faImage)
library.add(faArrowLeft)
library.add(faCheck)
library.add(faCircleQuestion)
library.add(faBriefcase)
library.add(faBroom)
library.add(faUndo)
library.add(faComments)
library.add(faClone)
library.add(faIdCard)
library.add(faFolderOpen)
library.add(faChildren)
library.add(faMessage)
library.add(faEllipsisVertical)
library.add(faAtom)
library.add(faFloppyDisk)
library.add(faXmark)
library.add(faTrashAlt)
library.add(faTools)
library.add(faPlusCircle)
library.add(faSave)
library.add(faUndoAlt)
library.add(faDownload)
library.add(faArrowRightToBracket)
library.add(faArrowRightFromBracket)
library.add(faPenToSquare)
library.add(faServer)
library.add(faAngleRight)
library.add(faAngleLeft)
library.add(faScrewdriverWrench)
library.add(faVideo)
library.add(faPlay)
library.add(faChalkboardTeacher)
library.add(faUserGraduate)
library.add(faFileImport)
library.add(faSort)
library.add(faFilter)
const app = createApp(App)



const vuetify = createVuetify({ components, directives,
        theme: {
            dark: false,
            defaultTheme: 'myCustomLightTheme',
            themes: {
                myCustomLightTheme:  {
                    dark: false,
                    colors: {
                        background: '#a1938b',
                        surface: '#E7EBF3',
                        primary: '#579AEA',
                        'primary-darken-1': '#e75908',
                        secondary: '#FFC107',
                       'secondary-darken-1': '#b69406',
                        error: '#F44336',
                        info: '#E7717D',
                        success: '#AFD275',
                        'success-darken-1': '#AFD275',
                        warning: '#F44336',
                        accent: '#FF9800',
                        gray:'#424242',
                    }
                },
            },
    }})

//app.use(VueLodash, { lodash: lodash })
    app.use(router)
    app.use(vuetify)
    app.use(lodash)
    app.use(VueAxios, axios)
    app.config.globalProperties.$axios=axios
    app.use(store)
    app.use(AvatarCropper)
    app.component('datePicker', Datepicker);
    app.component('font-awesome-icon', FontAwesomeIcon)
    app.mount('#app')
    app.use(VueScrollTo)

