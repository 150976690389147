<template>
  <v-container>
    <v-card class="mx-auto chat_card_ligth_rounded"  >
      <v-tabs v-model="tab" background-color="primary" >
        <v-tab :value="1" >Чаты</v-tab>
        <v-tab :value="2" v-show="selectedChat!=undefined">{{selectedChatSubject}}</v-tab>
      </v-tabs>
      <v-card-text class="align-self-stretch h-75 ma-0 pa-0">
        <v-window v-model="tab" class="align-self-stretch" >
          <v-window-item :value="1" style="border-radius: 15px;"  class="align-self-stretch">
            <v-card-title
                style="min-height: 5vh;"
                class="ma-0 pa-6 chat_card_ligth_header">
              <v-row>
                <v-col cols=12>
                  <v-text-field
                      v-model="chatSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded append-icon="mdi-plus-circle-outline"
                      @click:append="showCreateChatDialog=true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card
                    variant="tonal"
                    class="ma-5 mx-5 w-100"
                    v-for="(item) in chatPaginatedData"
                    :key="item.id">
                  <v-card-title class="chat_card_ligth_header">
                    <h5>Чат</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text >
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Тема:</v-list-item-title>
                        <v-list-item-subtitle>{{item.subject }}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Собеседники:</v-list-item-title>
                        <v-list-item-subtitle> <v-chip-group active-class="primary--text" column>
                          <v-chip size="small" class="chip_ligth" v-for="pg in item.chatPersons" :key="pg">{{pg.firstname}} {{pg.lastname}}</v-chip>
                        </v-chip-group></v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Создан:</v-list-item-title>
                        <v-list-item-subtitle>{{(item.createDate).replace('T', ' ')}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="chat_card_ligth_actions arrow-left">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="selectChat(item)"
                        rounded
                        style="margin: 5px;"
                        variant="outlined"
                       ><font-awesome-icon icon="fa-solid fa-comments" />
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
              <div class="d-none d-sm-block">
                <v-table fixed-header class="h-100">
                  <thead>
                  <tr>
                    <th class="text-left">Собеседники <font-awesome-icon v-on:click="byChatPersons" icon="fa-solid fa-sort" /></th>
                    <th class="text-left">Тема <font-awesome-icon v-on:click="bySubject" icon="fa-solid fa-sort" /></th>
                    <th class="text-left">Создан <font-awesome-icon v-on:click="byCreateDate" icon="fa-solid fa-sort" /></th>
                    <th class="text-left">Действие </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item) in chatPaginatedData" :key="item.id" >
                    <td>
                      <v-chip-group active-class="primary--text" column>
                        <v-chip class="chip_ligth" size="small" v-for="pg in item.chatPersons" :key="pg">{{pg.firstname}} {{pg.lastname}}</v-chip>
                      </v-chip-group>
                    </td>
                    <td>{{item.subject }}</td>
                    <td>{{(item.createDate).replace('T', ' ')}}</td>
                    <td>
                      <v-btn  @click="selectChat(item)" style="margin: 5px;" rounded  variant="outlined" class="chip_ligth"><font-awesome-icon icon="fa-solid fa-comments" /></v-btn>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions style="min-height: 5vh;"   class="chat_card_ligth_actions" >
              <v-container class="text-center">
                <v-pagination
                    v-model="chatPage"
                    :length="chatPageCount"
                    :total-visible="3"
                    size="small"
                ></v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
          <v-window-item :value="2" style="border-radius: 15px;" class="align-self-stretch" >
            <v-card>
              <v-card-title style="min-height: 5vh;" class=" ma-0 pa-6 chat_card_ligth_actions">
                <v-row>
                  <v-col cols=12>
                    <v-text-field
                        v-model="msgBody"
                        variant="outlined"
                        type="text"
                        label="Введите сообщение"
                        hint="Введите сообщение"
                        rounded append-icon="mdi-send"
                        @keyup.enter="sendMsg(selectedChat)"
                        @click:append-outer="sendMsg(selectedChat)"
                        @click:append="sendMsg(selectedChat)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="d-sm-none d-flex  flex-column">
                  <v-alert class="ma-1"
                      v-for="item in chatMessagesPaginatedData " :key="item"
                      variant="tonal"
                      :class="item.author.id==user.id ? 'my_message_ligth' : 'other_message_ligth'"
                  >
                  <div>
                    {{item.messageBody}}
                  </div>
                  <v-divider class="my-4 bg-light-blue-lighten-4"></v-divider>

                  <div class="d-flex flex-row align-center justify-space-between">
                    <div>
                      <span>
                        <sub style="font-size: 0.8rem;">
                          {{item.author.firstname}} {{item.author.lastname}}  -  {{ (item.messageCreateDate).replace('T', ' ') }}
                        </sub>
                      </span>
                    </div>
                  </div>

                  </v-alert>

                </div>
                <div class="d-none d-sm-block">
                  <v-timeline>
                  <v-timeline-item
                      size="large"
                      v-for="item in chatMessagesPaginatedData "
                      :key="item"
                      :dot-color="item.author.id==user.id ? '#C8E6C9':'#BBDEFB'"
                  >
                    <template v-slot:icon>
                        <span >{{item.author.firstname.substring(0, 1)}}</span>
                    </template>
                    <template v-slot:opposite>
                      <span><sub style="font-size: 0.8rem;">
                          {{ (item.messageCreateDate).replace('T', ' ') }}
                        </sub></span>
                    </template>

                    <v-card
                        :class="item.author.id==user.id ? 'my_message_ligth' : 'other_message_ligth'"
                    >
                      <v-card-title class="text-h6" >
                        {{item.author.firstname}} {{item.author.lastname}}
                      </v-card-title>
                      <v-card-text>
                        <p>{{item.messageBody}}</p>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
                </div>
              </v-card-text>
            </v-card>

            <v-divider></v-divider>
            <v-card-actions style="min-height: 5vh;"  class="chat_card_ligth_actions">
              <v-container class="text-center">
                <v-pagination
                    v-model="chatMessagesPage"
                    :length="chatMessagesPageCount"
                    :total-visible="3"
                    size="small"
                ></v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
    <v-row justify="center">
      <v-dialog
          v-model="showCreateChatDialog"
          scrollable
          persistent
      >
        <v-card max-width="500"
                min-width="350">
          <v-card-title class="chat_card_ligth_header" >
            <h5>Новый чат</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="chatForm" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="chatPersons"
                        :items="employees"
                        :rules="chatPersonsRules"
                        :custom-filter="chatMemberFilter"
                        label="Собеседники"
                        variant="outlined"
                        chips
                        small-chips
                        closable-chips
                        multiple
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                            v-bind="props"
                            :text="item.raw.firstname +' '+item.raw.secname+' '+item.raw.lastname"
                        ></v-chip>
                      </template>
                      <template v-slot:item="{ props, item }">

                        <v-list-item
                            v-bind="props"
                            :title="item.raw.firstname +' '+item.raw.secname+' '+item.raw.lastname"
                            :subtitle="item.raw.locationDescription"
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        label="Тема"
                        v-model="subject"
                        :rules="subjectRules"
                        :counter="255"
                        variant="outlined"
                        hint="Укажите тему чата"
                        required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="chat_card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                variant="outlined"
                @click="closeChat"
                rounded
            >
              Отмена
            </v-btn>
            <v-btn
                variant="outlined"
                @click="saveChat"
                rounded
            >Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>


import {router} from "@/service/router"
import {apiUrl} from "@/service/user.service"
import _ from "lodash"


const SockJS = require("sockjs-client");
const Stomp = require('stompjs');






export default {
  name: 'ProfileChat',



  props: [],


  data: () => ({
    loading: false,
    myProfile: '',
    tab:1,
    noteCount:10,
    sortKey: 'id',
    lastKey: 'id',
    sortOrder: -1,

    chats: [],
    employees: [],
    chatSearchBody:'',
    showCreateChatDialog: false,
    chatPage:1,
    chatPersons: [],
    chatPersonsRules: [
      v => (!!v && true)  || 'Поле обязательно для заполнения!'
    ],
    subject: '',
    subjectRules: [
      v => !!v   || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 255) || 'Значение до должно превышать 255 символов!',
    ],

    selectedChat: undefined,
    selectedChatSubject: '',
    msgBody:'',
    msgs:[],
    chatMessagesPage: 1,

  }),
  methods: {
    chatMemberFilter(item, queryText){
      console.log("filter item: "+ item)
      const textFirstname = item.firstname.toLowerCase()
      const textLastname = item.lastname.toLowerCase()
      const textSecname = item.secname.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textFirstname.indexOf(searchText) > -1 ||
          textLastname.indexOf(searchText) > -1  ||
          textSecname.indexOf(searchText) > -1


    },
    sendMsg(selectedChat){
      console.log("msg: " + this.msgBody)
    //  this.msgBody = ''
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.$store.state.authentication.user.token
        }
      }

      let message = {
        messageBody: this.msgBody,
        sourceChatId: selectedChat.id,
      }
      this.axios.post(apiUrl +'/message/', message, jwtTokenConfig)
          .then(response => {
            if (response.status === 202) {
              sendMessage(message)

              this.msgs = response.data
              this.msgBody = ''
            } else {
              alert("что то пошло не так")
            }
            console.log(response)

          })
          .catch(function (error) {
            console.log(error)
          })
      this.dialog = false
      this.serverName = ''
      this.serverDescription = ''
      this.serverUrl = ''
      this.serverApiKey = ''
      this.active = false
      this.nameAlert=false
      this.id = ''

    },
    byChatPersons: function (){
      if(this.lastKey.includes('id')){
        this.sortOrder=this.sortOrder*-1
      }
      this.sortKey='id'
      this.lastKey = this.sortKey

    },
    bySubject: function (){
      if(this.lastKey.includes('subject')){
        this.sortOrder=this.sortOrder*-1
      }
      this.sortKey='subject'
      this.lastKey = this.sortKey
    },
    byCreateDate: function (){
      if(this.lastKey.includes('createDate')){
        this.sortOrder=this.sortOrder*-1
      }
      this.sortKey='eventBeginDateTime'
      this.lastKey = this.sortKey
      console.log('eventBeginDateTime, sortOrder:' + this.sortOrder)
    },
    saveChat() {

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.$refs.chatForm.validate().then(result=> {
        if(result.valid){
            let chat = {
              subject: capitalizeFirstLetter(this.subject),
              chatPersons: this.chatPersons,
            }

            this.axios.post(apiUrl +'/chat', chat, jwtTokenConfig)
                .then(response => {
                  if (response.status === 201) {
                    this.chats.push(response.data)
                    this.showCreateChatDialog = false
                    this.subject = ''
                    this.chatPersons = undefined
                  } else {
                    alert("что то пошло не так")
                  }
                  console.log(response)
                }).catch(function (error) {
                  console.log(error)
                  if(error.response.status===401){
                    router.push('/login').then(r => {console.log("error: " + r)})
                  }})
          }
      })
    },
    closeChat(){
      this.showCreateChatDialog = false
      this.chatPersons = undefined
      this.subject = ''
    },
    selectChat(item){

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.$store.state.authentication.user.token
        }
      }

      this.axios
          .get(apiUrl +'/message/'+ item.id , jwtTokenConfig)
          .then(res => {
            this.msgs = res.data
            this.selectedChat = item
            this.selectedChatSubject = item.subject
            this.tab=2
            console.log('chat msgs: ', res.data)
          })
          .catch(error => {
            console.log(error)
            if(error.response.status===401){
              router.push('/login').then(r => {console.log("error: " + r)})
            }

          })


    },

  },

computed:{

    isChild() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_CHILD')
      } else return false

    },
    isTeacher() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TEACHER')
      } else return false

    },
    isTutor() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TUTOR')
      } else return false

    },
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },
    filteredChats() {
      const ss = this.chatSearchBody.toLowerCase();
      let tempChats = this.chats

      return tempChats.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },
    sortedChats() {
      let tempChats = this.filteredChats
      return tempChats.sort(dynamicSort(this.sortKey,this.sortOrder));
    },
    chatPageCount(){
      let l = this.sortedChats.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l/s);
    },
    chatPaginatedData(){
      const start = (this.currentChatPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);
      return this.sortedChats.slice(start, end);
    },
    currentChatPage (){
      if(this.chatPageCount < this.chatPage){
        return 1
      } else {
        return this.chatPage
      }
    },
    chatMessagesPageCount(){
      let l = this.msgs.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l/s);
    },
    chatMessagesPaginatedData(){

      if(this.msgs!=null){
        for (let i = 0; i < this.msgs.length; i++) {
          console.log(encode(this.msgs[i].messageBody, this.selectedChat.chatSalt))

        }
      }

      let decryptedMsgs = this.msgs.map(obj => {
          console.log("msg: " + obj.messageBody)
          console.log("this.selectedChat.chatSalt : "+ this.selectedChat.chatSalt)

          let msg = encode(obj.messageBody, this.selectedChat.chatSalt)
          console.log("decoded msg: " + msg)

          return {...obj, messageBody: msg};
      });

      let start = (this.currentChaMessagestPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);
      return decryptedMsgs.slice(start, end);
    },
    currentChaMessagestPage (){
      if(this.chatMessagesPageCount < this.chatMessagesPage){
        return 1
      } else {
        return this.chatMessagesPage
      }
    },
  },

  created: function () {

    let jwtTokenConfigForStomp = {
      transports: ['xhr-streaming'],
      headers: {Authorization: "Bearer " + this.user.token}
    }

    connect(jwtTokenConfigForStomp);

    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token
      }
    }


    function getChats(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/chat', jwtTokenConfig)
    }

    function getEmployees(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/user/employees', jwtTokenConfig)
    }


    Promise.all([
      getChats(jwtTokenConfig, this.axios),
      getEmployees(jwtTokenConfig, this.axios)
    ])
        .then(results => {

          if(results[0].status===200){
            this.chats = results[0].data;
            console.log("size chats: " + this.chats.length)
          } else if (results[0].status===401){
            router.push('/login').then(r => {console.log("error: " + r)})
          }
          if(results[1].status===200){
            this.employees = results[1].data;
            console.log("size employees: " + this.employees.length)
          } else if (results[1].status===401){
            router.push('/login').then(r => {console.log("error: " + r)})
          }
        });



    addHandler(data=> {
      if (data.objectType === 'MESSAGE') {
        if (data.body.sourceChatId===this.selectedChat.id){
          switch (data.eventType) {
            case 'CREATE':
              this.msgs.push(data.body)
              this.msgs =  this.msgs.sort(dynamicSort('messageCreateDate',-1))
              break

            default:
              console.error(`Произошедшее событие - неведомая бубуйня: "${data.eventType}"`)
          }
        }

      } else {
        console.error(`Прилетевший обьект какая то неведомая бубуйня: "${data.objectType}"`)
      }
    })

  }


}


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function encode(source, key) {
  const CryptoJS = require("crypto-js")
  let chatSecret = CryptoJS.enc.Base64.parse(key)
  let decrypted3 = CryptoJS.AES.decrypt(source, chatSecret, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
  return CryptoJS.enc.Utf8.stringify(decrypted3);
}

function dynamicSort(property, sortOrder) {
  return function (a,b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

var stompClient = null
const handlers = []

function connect() {
  const socket = new SockJS('https://school-backend.eurekacenter.ru/gs-guide-websocket')
  stompClient = Stomp.over(socket)
  stompClient.connect({}, frame => {
    console.log('Connected: ' + frame)
    stompClient.subscribe('/topic/activity', message => {
      handlers.forEach(handler => handler(JSON.parse(message.body)))
    })
  })
}

function addHandler(handler) {
  handlers.push(handler)
}


function sendMessage(message) {
  stompClient.send("/app/changeLection", {}, JSON.stringify(message))
}


</script>
<style>
.my_message_ligth {

  background-color: #C8E6C9!important;
  color: #212121!important;
}
.other_message_ligth {
  background-color: #BBDEFB!important;
  color: #212121!important;

}
.chip_ligth {

  background-color: #00838F!important;
  color: #ffffff!important;

}
.chat_card_ligth_header {

  background-color: #90A4AE!important;
  color: #ffffff!important;

}

.chat_card_ligth_header_rounded {

  background-color: #90A4AE !important;
  color: #263238 !important;
}
.chat_card_ligth_header {

  background-color: #ffffff!important;
  color: #1E88E5!important;

}


.chat_card_ligth_actions {
  background-color: #ffffff!important;
  color: #1E88E5!important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}


.chat_card_ligth {
  border-radius:5px;
  opacity: .95;

}

.chat_card_ligth_rounded_bottom {
  opacity: .95;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.chat_card_ligth_rounded {
  border-radius:5px;
  opacity: .95;
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);


}

.chat_card_dark {
  border-radius:5px;
  opacity: .95;
  background-color: #ffffff!important;
  color: #42A5F5!important;
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
}

</style>
