<template>
  <v-layout>
    <v-app-bar
        color="primary"
        prominent
    >
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Sakhalin School</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="makeLogout" v-if="loggedIn" variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"/></v-btn>
      <v-btn @click="makeLogin" v-else variant="text"><font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket"/></v-btn>
      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>

      <v-navigation-drawer
          class="v-navigation-drawer"
          v-model="drawer"
          expand-on-hover
          rail
      >
        <v-list v-if="isUser">
          <v-list-item v-if="((myProfile!==null)&(myProfile.personAvatar!==null))"
              :prepend-avatar="'/avatar/' + myProfile.personAvatar"
              :title="myProfile.firstname + ' ' + myProfile.lastname"
              :subtitle=myProfile.userEmail
          ></v-list-item>
          <v-list-item v-else
                       :title="myProfile.firstname + ' ' + myProfile.lastname"
                       :subtitle=myProfile.userEmail
          ></v-list-item>

        </v-list>

        <v-divider></v-divider>

        <v-list v-if="isUser" density="compact" nav>
          <v-list-item prepend-icon="mdi-home" title="Домой"  @click="goHome"></v-list-item>
          <v-list-item prepend-icon="mdi-account-settings" title="Профиль"  @click="sidebarItem=0"></v-list-item>
          <v-list-item prepend-icon="mdi-comment" title="Сообщения" @click="sidebarItem=1"></v-list-item>
          <v-list-item prepend-icon="mdi-folder" title="Файлы" @click="sidebarItem=2"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main >
        <v-container grid-list-md text-xs-center style="display: flex; min-height: 70vh; flex-direction: column; justify-content: space-between;">
          <v-layout row wrap>
            <v-container>
              <ProfileCard v-if="Number(sidebarItem)===0" :user="user" :myProfile="myProfile"></ProfileCard>
              <profile-chat v-if="Number(sidebarItem)===1"></profile-chat>
              <FileCard v-if="Number(sidebarItem)===2"></FileCard>
            </v-container>
          </v-layout>

        </v-container>

      </v-main>
  </v-layout>
  <site-footer></site-footer>

</template>
<script>
import ProfileCard from './ProfileCard.vue'
import FileCard from './FileCard.vue'
import SiteFooter from "@/components/sections/SiteFooter";
import {router} from "@/service/router";
import {apiUrl} from "@/service/user.service";
import ProfileChat from "@/components/Profile/ProfileChat"
import { useTheme } from 'vuetify'

export default {
  name: 'ProfilePage',
  setup () {
    const theme = useTheme()

    if (localStorage.colorTheme) {
      console.log("theme exist :" + localStorage.colorTheme)
      theme.global.name.value = localStorage.colorTheme
    } else {
      theme.global.name.value = 'dark'
    }

    return {
      theme,
      toggleTheme: () => {
        if(theme.global.current.value.dark) {
          localStorage.colorTheme = 'light'
          theme.global.name.value = 'light'
          console.log("set ligth")
        } else {
          localStorage.colorTheme = 'dark'
          theme.global.name.value = 'dark'
          console.log("set dark")
        }
      }

      // theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'}
    }
  },
  components: {
    ProfileCard,
    FileCard,
    SiteFooter,
    ProfileChat
  },
  props: {
    source: String,
  },
  watch: {
    sidebarItem(newSidebarItem) {
      console.log("newSidebarItem: " + newSidebarItem)
      if(localStorage.ProfileSidebarItem !== newSidebarItem){
        localStorage.ProfileSidebarItem = newSidebarItem;
      }
    },
  },
  mounted() {
    if (localStorage.ProfileSidebarItem) {
      this.sidebarItem = JSON.parse(localStorage.ProfileSidebarItem)
    } else {
      this.sidebarItem=0
    }
  },
  data () {
    return {
      drawer: true,
      sidebarItem: '',
      myProfile: ''
    }
  },
  computed: {
    isUser() {
      if(this.myProfile!= undefined){
        return true
      } else{
        return false
      }

    },

    isParent() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_PARENT')
      } else return false

    },
    isChild() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_CHILD')
      } else return false

    },
    isTeacher() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TEACHER')
      } else return false

    },
    isTutor() {
      if(this.myProfile.roles!= undefined){
        return this.myProfile.roles.includes('ROLE_TUTOR')
      } else return false

    },
    loggedIn () {
      return this.$store.state.authentication.status.loggedIn
    },
    user () {
      return this.$store.state.authentication.user
    },

  },
  methods:  {
    makeLogout () {
      router.push('/login').then(r => {console.log("error: " + r)})
    },
    makeLogin () {
      router.push('/login').then(r => {console.log("error: " + r)})

    },
    goHome(){
      router.push('/').then(r => {console.log("error: " + r)})
    }

  },
  created() {
    console.log("user: " + this.user)
    if(this.user){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.axios
          .get(apiUrl +'/user/i', jwtTokenConfig)
          .then(res => {
            this.myProfile = res.data
            console.log('myProfile: ', res.data)

          }).catch(error => {
        console.log(error)
        if(error.response.status===401){
          router.push('/').then(r => {console.log("error: " + r)})
        } else {
          router.push('/').then(r => {console.log("error: " + r)})
        }

      })
    } else {
      router.push('/').then(r => {console.log("error: " + r)})
    }

  }

};
</script>

<style>
.bg-active {
  background-color: black;
  color : white !important;
}

</style>