<template>
  <v-container>
    <v-card class="mx-auto ma-10">
          <v-tabs v-model="tab" background-color="primary" >
            <v-tab value="1" >Пользователи</v-tab>
            <v-tab value="2">Группы пользователей</v-tab>
          </v-tabs>
          <v-card-text class="align-self-stretch h-75 ma-0 pa-0">
            <v-window v-model="tab" class="align-self-stretch">
              <v-window-item value="1" class="align-self-stretch">
                <v-card-title class="card_ligth_header">
                  <h3>Пользователи</h3>
                </v-card-title>
                <v-card-title style="min-height: 5vh;" class="card_ligth_header">
                  <v-row>
                    <v-col cols=12>
                      <v-text-field
                          v-model="searchBody"
                          label="Найти"
                          variant="outlined"
                          clearable
                          hint="Введите ключевое слово для поиска"
                          rounded
                          append-icon="mdi-account-plus"
                          @click:append="dialog=true"
                      >
                      </v-text-field>
                    </v-col>
                    <v-btn @click="sendMassNotification()" style="margin: 5px;" size="small" variant="outlined" color="accent" rounded><font-awesome-icon icon="fa-solid fa-envelope" /></v-btn>
                  </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="min-height: 55vh;">
                  <div class="d-sm-none d-flex align-center flex-column">
                    <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="item in paginatedData" :key="item">
                      <v-card-title class="card_ligth_header">
                        <h5>   Пользователь № {{filteredUsers.indexOf(item) +1}}</h5>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text >
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>ФИО: </v-list-item-title>
                            <v-list-item-subtitle>{{ item.lastname }} {{ item.firstname }} {{ item.secname }}</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>E-mail: </v-list-item-title>
                            <v-list-item-subtitle>{{ item.personEmail }}</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Организация: </v-list-item-title>
                            <v-list-item-subtitle>{{ item.personOrganization }}</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Специальность: </v-list-item-title>
                            <v-list-item-subtitle>{{ item.personSpeciality }}</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Почтовый адрес организации: </v-list-item-title>
                            <v-list-item-subtitle>{{ item.personOrganizationPostAddress }}</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Дата регистрации: </v-list-item-title>
                            <v-list-item-subtitle>{{ (item.registrationDate).replace('T', ' ') }} </v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Очное участие: </v-list-item-title>
                            <v-list-item-subtitle><p v-if="!!item.inHall">Да</p><p v-else>Нет</p></v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Онлайн участие: </v-list-item-title>
                            <v-list-item-subtitle><p v-if="!!item.inOnline">Да</p><p v-else>Нет</p></v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Роли: </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-chip-group active-class="primary--text" column>
                                <v-chip size="small" v-for="rol in item.roles" :key="rol">{{rol}}</v-chip>
                              </v-chip-group>
                            </v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Группы: </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-chip-group active-class="primary--text" column>
                                <v-chip size="small" v-for="pg in item.personGroups" :key="pg">{{pg.personGroupName}}</v-chip>
                              </v-chip-group>
                            </v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="card_ligth_actions">
                        <v-btn @click="sendNotification(item)" style="margin: 5px;" size="small" variant="outlined" color="accent" rounded><font-awesome-icon icon="fa-solid fa-envelope" /></v-btn>
                        <v-btn @click="editUser(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                        <v-btn @click="deleteUser(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                      </v-card-actions>
                    </v-card>
                  </div>
                  <div class="d-none d-sm-block">
                    <v-table
                        fixed-header
                        class="h-100"
                    >
                      <thead>
                      <tr>
                        <th class="text-left">№ <font-awesome-icon v-on:click="byId" icon="fa-solid fa-sort" /></th>
                        <th class="text-left">ФИО <font-awesome-icon v-on:click="byLastname" icon="fa-solid fa-sort" /></th>
                        <th class="text-left">E-mail <font-awesome-icon v-on:click="byPersonEmail" icon="fa-solid fa-sort" /></th>
                        <th class="text-left">Организация</th>
                        <th class="text-left">Специальность</th>
                        <th class="text-left">Почтовый адрес организации</th>
                        <th class="text-left hidden-md-and-down">Очно <font-awesome-icon v-on:click="byInHall" icon="fa-solid fa-sort" /></th>
                        <th class="text-left hidden-md-and-down">Онлайн <font-awesome-icon v-on:click="byInOnline" icon="fa-solid fa-sort" /></th>
                        <th class="text-left hidden-md-and-down">Телефон</th>
                        <th class="text-left hidden-md-and-down">Добавлен <font-awesome-icon v-on:click="byRegistrationDate" icon="fa-solid fa-sort" /></th>
                        <th class="text-left hidden-md-and-down">Роли</th>
                        <th class="text-left hidden-md-and-down">Группы</th>
                        <th class="text-left">Действие</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item) in paginatedData" :key="item.id" >
                        <td>{{filteredUsers.indexOf(item) +1}}</td>
                        <td>{{ item.lastname }} {{ item.firstname }} {{ item.secname }}</td>
                        <td>{{ item.personEmail }}</td>
                        <td>{{ item.personOrganization }}</td>
                        <td>{{ item.personSpeciality }}</td>
                        <td>{{ item.personOrganizationPostAddress }}</td>
                        <td><p v-if="!!item.inHall">Да</p><p v-else>Нет</p></td>
                        <td><p v-if="!!item.inOnline">Да</p><p v-else>Нет</p></td>
                        <td class="hidden-md-and-down">{{ item.personMobilePhone }}</td>
                        <td class="hidden-md-and-down">{{(item.registrationDate).replace('T', ' ')}}</td>
                        <td class="hidden-md-and-down">
                          <v-chip-group active-class="primary--text" column>
                            <v-chip size="small" v-for="rol in item.roles" :key="rol">{{rol}}</v-chip>
                          </v-chip-group>
                        </td>
                        <td class="hidden-md-and-down">
                          <v-chip-group active-class="primary--text" column>
                            <v-chip size="small" v-for="pg in item.personGroups" :key="pg">{{pg.personGroupName}}</v-chip>
                          </v-chip-group>
                        </td>
                        <td>
                          <v-btn @click="sendNotification(item)" style="margin: 5px;" size="small" variant="outlined" color="accent" rounded><font-awesome-icon icon="fa-solid fa-envelope" /></v-btn>
                          <v-btn @click="editUser(item)" style="margin: 5px;" size="small" variant="outlined" color="accent" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                          <v-btn @click="deleteUser(item)" style="margin: 5px;" size="small" variant="outlined" color="warning" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </v-table>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions style="min-height: 5vh;" class="card_ligth_actions">
                  <v-container class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :total-visible="3"
                        size="small"
                    ></v-pagination>
                  </v-container>
                </v-card-actions>
              </v-window-item>
              <v-window-item value="2" class="align-self-stretch">
                <v-card-title class="card_ligth_header">
                  <h3>Группы пользователей</h3>
                </v-card-title>
                <v-card-title style="min-height: 5vh;" class="card_ligth_header">
                  <v-row>
                    <v-col cols=12>
                      <v-text-field
                          v-model="groupSearchBody"
                          label="Найти"
                          variant="outlined"
                          clearable
                          hint="Введите ключевое слово для поиска"
                          rounded
                          append-icon="mdi-account-multiple-plus"
                          @click:append="groupDialog=true"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="min-height: 50vh;">
                  <div class="d-sm-none d-flex align-center flex-column">
                    <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="(item) in groupsPaginatedData" :key="item.id">
                      <v-card-title class="card_ligth_header">
                        <h5>Группа пользователей № {{filteredUserGroups.indexOf(item) +1}}</h5>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text >
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Название: </v-list-item-title>
                            <v-list-item-subtitle>{{ item.personGroupName }}</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Описание:</v-list-item-title>
                            <v-list-item-subtitle>{{ item.personGroupDescription }}</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Добавлена:</v-list-item-title>
                            <v-list-item-subtitle>{{ (item.personGroupCreateDate).replace('T', ' ')  }}</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                        <v-list-item lines="two">
                          <v-list-item-header>
                            <v-list-item-title>Активна:</v-list-item-title>
                            <v-list-item-subtitle v-if="item.isLocked">Нет</v-list-item-subtitle>
                            <v-list-item-subtitle v-else>Да</v-list-item-subtitle>
                          </v-list-item-header>
                        </v-list-item>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="card_ligth_actions">
                        <v-btn @click="editUserGroup(item)" style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                        <v-btn @click="deleteUserGroup(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                      </v-card-actions>
                    </v-card>
                  </div>
                  <div class="d-none d-sm-block">
                    <v-table
                        fixed-header
                        class="h-100"
                        scrollble
                    >
                      <thead>
                      <tr>
                        <th class="text-left">
                          <b>№ <font-awesome-icon v-on:click="byGroupId" icon="fa-solid fa-sort" /></b>
                        </th>
                        <th class="text-left">
                          <b>Название</b> <font-awesome-icon v-on:click="byPersonGroupName" icon="fa-solid fa-sort" />
                        </th>
                        <th class="text-left">
                          <b class="d-none d-sm-inline-flex">Описание</b>  <font-awesome-icon v-on:click="byPersonGroupDescription" icon="fa-solid fa-sort" />
                        </th>
                        <th class="text-left">
                          <b class="d-none d-sm-inline-flex">Добавлена</b> <font-awesome-icon v-on:click="byPersonGroupCreateDate " icon="fa-solid fa-sort" />
                        </th>
                        <th class="text-left">
                          <b>Активна</b> <font-awesome-icon v-on:click="byIsLocked" icon="fa-solid fa-sort" />
                        </th>
                        <th class="text-left">
                          <b>Действие</b>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in groupsPaginatedData" :key="item.id" >
                        <td><p>{{filteredUserGroups.indexOf(item) +1}}</p></td>
                        <td><p>{{ item.personGroupName }}</p></td>
                        <td><p class="d-none d-sm-inline-flex">{{ item.personGroupDescription }}</p></td>
                        <td><p class="d-none d-sm-inline-flex">{{ (item.personGroupCreateDate).replace('T', ' ') }}</p></td>
                        <td><p v-if="item.isLocked">Нет</p><p v-else>Да</p></td>
                        <td>
                          <p>
                            <v-btn @click="editUserGroup(item)" style="margin: 5px;" size="small" mx-1 variant="outlined" color="accent" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                            <v-btn @click="deleteUserGroup(item)" style="margin: 5px;" size="small" variant="outlined" color="warning" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                          </p>
                        </td>
                      </tr>
                      </tbody>
                    </v-table>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions style="min-height: 5vh;" class="card_ligth_actions">
                  <v-container class="text-center">
                    <v-pagination
                        v-model="groupPage"
                        :length="groupPageCount"
                        :total-visible="3"
                        size="small"
                    ></v-pagination>
                  </v-container>
                </v-card-actions>
              </v-window-item>
            </v-window>
          </v-card-text>
    </v-card>
  </v-container>

  <v-dialog
     v-model="dialog"
     scrollable
  >
    <v-card max-width="500"
            min-width="350">
      <v-card-title class="card_ligth_header">
         <h5>Пользователь</h5>
      </v-card-title>
              <v-card-text>
                <v-form
                  ref="userForm"
                 >
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                      label="Фамилия*"
                      v-model="lastname"
                      :rules="lastnameRules"
                      hint="Укажите фамилию"
                      :counter="25"
                      variant="outlined"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      label="Имя*"
                      v-model="firstname"
                      :rules="firstnameRules"
                      :counter="25"
                      variant="outlined"
                      hint="Укажите имя"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      label="Отчество*"
                      v-model="secname"
                      :rules="secnameRules"
                      variant="outlined"
                      :counter="25"
                      hint="Укажите отчество"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="personEmail"
                      variant="outlined"
                      label="Email*"
                      hint="Укажите e-mail"
                  ></v-text-field>
                  <v-alert
                      density="compact"
                      type="error"
                      variant="outlined"
                      v-show="existEmail"
                  >E-mail уже занят!
                  </v-alert>
                </v-col>
                      <v-col cols="12">
                        <v-text-field
                            v-model="username"
                            :rules="usernameRules"
                            variant="outlined"
                            label="Имя пользователя*"
                            hint="Укажите имя пользователя (используется для входа в систему)"
                            required
                        ></v-text-field>
                      </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="password"
                      label="Пароль*"
                      contenteditable="false"
                      type="text"
                      variant="outlined"
                      hint="Сгенерированный пароль"
                      required
                      persistent
                  >
                    <template v-slot:append>
                      <v-icon
                          color="red"
                          v-on:click="generatePassword"
                      >
                        mdi-hammer
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>


                      <v-col
                          cols="12"
                      >
                        <v-switch
                            v-model="inHall"
                            variant="outlined"
                            label="Очное участие"

                        ></v-switch>
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-switch
                            v-model="inOnline"
                            variant="outlined"
                            label="Онлайн участие"

                        ></v-switch>
                      </v-col>



                <v-col cols="12">
                  <v-autocomplete
                      v-model="roles"
                      :items="rolesItems"
                      outlined
                      dense
                      chips
                      small-chips
                      variant="outlined"
                      closable-chips
                      label="Роли"
                      multiple
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                      v-model="personGroups"
                      :items="userGroups"
                      :custom-filter="userGroupFilter"
                      filled
                      chips
                      small-chips
                      variant="outlined"
                      closable-chips
                      label="Группы пользователей"
                      item-title="name"
                      item-value="name"
                      multiple
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.personGroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">

                      <v-list-item

                          v-bind="props"
                          :title="item.raw.personGroupName"
                          :subtitle="item.raw.personGroupDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closePersonForm"
          >
            Отмена
          </v-btn>
          <v-btn
              rounded
              variant="outlined"
              @click="save"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  <v-dialog
      v-model="groupDialog"
      scrollable
  >
    <v-card max-width="500"
            min-width="350" >
      <v-card-title class="card_ligth_header">
        <h5>Группа пользователей</h5>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="userGroupForm"
        >
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Название*"
                    v-model="personGroupName"
                    :rules="personGroupNameRules"
                    hint="Укажите название группы"
                    :counter="30"
                    required
                    variant="outlined"
                ></v-text-field>
                <v-alert
                    density="compact"
                    type="error"
                    v-show="existGroupError"
                >Группа уже существует!
                </v-alert>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-textarea
                    label="Описание*"
                    v-model="personGroupDescription"
                    :rules="personGroupDescriptionRules"
                    :counter="255"
                    hint="Укажите описание"
                    variant="outlined"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-container
                    class="px-0"
                    fluid
                >
                  <v-switch
                      v-model="isLocked"
                      :label="isLocked ? 'Заблокирована' : 'Активна'"
                  ></v-switch>
                </v-container>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeUserGroupForm"
        >
          Отмена
        </v-btn>
        <v-btn
            rounded
            variant="outlined"
            @click="saveGroup"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
      v-model="showLoader"
      persistent
  ><v-alert><span class="text-h5 text-primary">Загрузка данных <v-progress-circular
      indeterminate
      color="primary"
  ></v-progress-circular></span></v-alert>
  </v-dialog>
</template>

<script>
import {router} from "@/service/router";
import _ from 'lodash';
import {apiUrl} from "@/service/user.service"

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminUserPage',

  props: [],
  data: () => ({
    inHall:false,
    inOnline:false,
    notificationBody:'',
    notificationDialog:false,
    firstnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    lastnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    secnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    usernameRules: [
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],

    rolesItems: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PARENT', 'ROLE_CHILD','ROLE_TEACHER', 'ROLE_TUTOR', 'ROLE_MASTER'],
    firstname: '',
    page: 1,
    noteCount: 100,
    lastname: '',
    secname: '',
    password: '',
    personEmail: '',
    personGroups: [],
    roles: [],
    //personGroupsSource:[],
    valid: true,
    dialog: false,
    searchBody: '',
    showInput: false,
    loading: false,
    users: [],
    emailForCheck: {},
    existEmail: false,
    username: '',

    groupSearchBody:'',
    groupDialog:false,
    personGroupName:'',
    personGroupDescription:'',
    isLocked:false,
    existGroupError:false,
    personGroupNameForCheck:'',
    groupPage:1,
    userGroups: [],
    tab:1,
    personGroupNameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 30) || 'Значение до должно превышать 30 символов!',
    ],
    personGroupDescriptionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 255) || 'Значение до должно превышать 255 символов!',
    ],
    userSortKey: 'id',
    userLastSortKey: 'id',
    userSortOrder: 1,


    userGroupSortKey: 'id',
    userGroupLastSortKey: 'id',
    userGroupSortOrder: 1,

    showLoader:false,




  }),
  methods: {

    sendNotification(item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .get(apiUrl + '/user/send_notification/' + item.id, jwtTokenConfig)
          .then(response => {
            if (response.status === 200) {
              alert("Оповещение отправлено!")
            } else {
              console.log(response)
            }
          })
          .catch(function (error) {
            console.log(error)
            //  if(error.response.status===401){
            //    router.push('/login').then(r => {console.log("error: " + r)})
            //  }
          })

    },
    sendMassNotification() {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .get(apiUrl + '/user/send_notification/mass', jwtTokenConfig)
          .then(response => {
            if (response.status === 200) {
              alert("Оповещения отправлены!")
            } else {
              console.log(response)
            }
          })
          .catch(function (error) {
            console.log(error)
            //  if(error.response.status===401){
            //    router.push('/login').then(r => {console.log("error: " + r)})
            //  }
          })

    },
    byInHall: function () {
      if (this.userLastSortKey.includes('inHall')) {
        this.userSortOrder = this.userSortOrder * -1
      }
      this.userSortKey = 'inHall'
      this.userLastSortKey = this.userSortKey
    },
    byInOnline: function () {
      if (this.userLastSortKey.includes('inOnline')) {
        this.userSortOrder = this.userSortOrder * -1
      }
      this.userSortKey = 'inOnline'
      this.userLastSortKey = this.userSortKey
    },
    byId: function () {
      if (this.userLastSortKey.includes('id')) {
        this.userSortOrder = this.userSortOrder * -1
      }
      this.userSortKey = 'id'
      this.userLastSortKey = this.userSortKey
    },
    byLastname: function () {
      if (this.userLastSortKey.includes('lastname')) {
        this.userSortOrder = this.userSortOrder * -1
      }
      this.userSortKey = 'lastname'
      this.userLastSortKey = this.userSortKey
    },
    byPersonEmail: function () {
      if (this.userLastSortKey.includes('personEmail')) {
        this.userSortOrder = this.userSortOrder * -1
      }
      this.userSortKey = 'personEmail'
      this.userLastSortKey = this.userSortKey
    },
    byRegistrationDate: function () {
      if (this.userLastSortKey.includes('registrationDate')) {
        this.userSortOrder = this.userSortOrder * -1
      }
      this.userSortKey = 'registrationDate'
      this.userLastSortKey = this.userSortKey
    },

    byGroupId: function () {
      if (this.userGroupLastSortKey.includes('id')) {
        this.userGroupSortOrder = this.userGroupSortOrder * -1
      }
      this.userGroupSortKey = 'id'
      this.userGroupLastSortKey = this.userGroupSortKey
    },

    byPersonGroupName: function () {
      if (this.userGroupLastSortKey.includes('personGroupName')) {
        this.userGroupSortOrder = this.userGroupSortOrder * -1
      }
      this.userGroupSortKey = 'personGroupName'
      this.userGroupLastSortKey = this.userGroupSortKey
    },
    byPersonGroupDescription: function () {
      if (this.userGroupLastSortKey.includes('personGroupDescription')) {
        this.userGroupSortOrder = this.userGroupSortOrder * -1
      }
      this.userGroupSortKey = 'personGroupDescription'
      this.userGroupLastSortKey = this.userGroupSortKey
    },
    byPersonGroupCreateDate: function () {
      if (this.userGroupLastSortKey.includes('personGroupCreateDate')) {
        this.userGroupSortOrder = this.userGroupSortOrder * -1
      }
      this.userGroupSortKey = 'personGroupCreateDate'
      this.userGroupLastSortKey = this.userGroupSortKey
    },

    byIsLocked: function () {
      if (this.userGroupLastSortKey.includes('isLocked')) {
        this.userGroupSortOrder = this.userGroupSortOrder * -1
      }
      this.userGroupSortKey = 'isLocked'
      this.userGroupLastSortKey = this.userGroupSortKey
    },


    editUserGroup: function (event) {
      this.id = event.id
      this.personGroupName = event.personGroupName
      this.personGroupDescription = event.personGroupDescription
      this.isLocked = event.isLocked
      this.groupDialog = true
    },
    deleteUserGroup: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl + '/user_group/' + item.id, jwtTokenConfig)
          .then(response => {
            if (response.status === 202) {
              this.userGroups.splice(this.userGroups.indexOf(item), 1)
            } else {
              console.log(response)
            }
          })
          .catch(function (error) {
            console.log(error)
            if (error.response.status === 401) {
              router.push('/login').then(r => {
                console.log("error: " + r)
              })
            }
          })
    },
    closeUserGroupForm() {
      this.groupDialog = false
      this.personGroupName = ''
      this.personGroupDescription = ''
      this.isLocked = false
    },
    saveGroup() {

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.$refs.userGroupForm.validate().then(responce => {
        if (responce.valid) {
          this.axios
              .get(apiUrl + '/user_group/exist/' + capitalizeFirstLetter(this.personGroupName), jwtTokenConfig)
              .then(res => {
                this.personGroupNameForCheck = res.data;
                console.log('personGroupNameForCheck: ', res.data);
                let userGroup = {
                  id: this.id,
                  personGroupName: capitalizeFirstLetter(this.personGroupName),
                  personGroupDescription: capitalizeFirstLetter(this.personGroupDescription),
                  isLocked: this.isLocked,
                }

                if (this.personGroupNameForCheck.groupExist) {
                  console.log("group exist")
                  if ((this.id) && (this.personGroupNameForCheck.ownerId === this.id) && (this.personGroupNameForCheck.name === this.personGroupName)) {
                    this.axios.put(apiUrl + '/user_group/' + this.id, userGroup, jwtTokenConfig)
                        .then(response => {
                          if (response.status === 202) {
                            let index = getIndex(this.userGroups, response.data.id)
                            this.userGroups.splice(index, 1, response.data)
                            this.groupDialog = false
                            this.id = ''
                            this.personGroupName = ''
                            this.personGroupDescription = ''
                            this.isLocked = false
                            this.existGroupError = false
                          } else {
                            alert("что то пошло не так")
                          }
                          console.log(response)

                        })
                        .catch(function (error) {
                          console.log(error)
                          if (error.response.status === 401) {
                            router.push('/login').then(r => {
                              console.log("error: " + r)
                            })
                          }
                        })
                  } else {
                    console.log("Группа с таким именем уже существует!")
                    this.existGroupError = true
                  }
                } else {
                  if (this.id) {
                    this.axios.put(apiUrl + '/user_group/' + this.id, userGroup, jwtTokenConfig)
                        .then(response => {
                          if (response.status === 202) {
                            let index = getIndex(this.userGroups, response.data.id)
                            this.userGroups.splice(index, 1, response.data)
                            this.groupDialog = false
                            this.id = ''
                            this.personGroupName = ''
                            this.personGroupDescription = ''
                            this.isLocked = false
                            this.existGroupError = false
                          } else {
                            alert("что то пошло не так")
                          }
                          console.log(response)
                        })
                        .catch(function (error) {
                          console.log(error)
                          if (error.response.status === 401) {
                            router.push('/login').then(r => {
                              console.log("error: " + r)
                            })
                          }
                        })
                  } else {
                    this.axios.post(apiUrl + '/user_group', userGroup, jwtTokenConfig)
                        .then(response => {
                          if (response.status === 201) {
                            this.userGroups.push(response.data)
                            this.groupDialog = false
                            this.personGroupName = ''
                            this.personGroupDescription = ''
                            this.isLocked = false
                            this.existGroupError = false
                          } else {
                            alert("что то пошло не так")
                          }
                          console.log(response)
                        })
                        .catch(function (error) {
                          console.log(error)
                          console.log(error)
                          if (error.response.status === 401) {
                            router.push('/login').then(r => {
                              console.log("error: " + r)
                            })
                          }
                        })

                  }

                }
              })
              .catch(error => console.log(error))
        }
      })


    },

    closePersonForm() {

      this.firstname = ''
      this.lastname = ''
      this.secname = ''
      this.personEmail = ''
      this.password = ''
      this.active = ''
      this.userEmail = ''
      this.roles = []
      this.personGroups = []
      this.id = ''
      this.dialog = false


    },
    clearSearch() {
      this.searchBody = ''
    },
    generatePassword() {
      let text = ""
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

      for (var i = 0; i < 13; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

      this.password = text
    },
    save() {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.$refs.userForm.validate().then(responce => {
            if (responce.valid) {

              let person = {
                firstname: capitalizeFirstLetter(this.firstname),
                lastname: capitalizeFirstLetter(this.lastname),
                secname: capitalizeFirstLetter(this.secname),
                username: this.username,
                personEmail: this.personEmail,
                password: this.password,
                active: this.active,
                userEmail: this.userEmail,
                roles: this.roles,
                personGroups: this.personGroups,
                inHall: this.inHall,
                inOnline: this.inOnline,
              }

              if (this.id) {
                this.axios.put(apiUrl + '/user/' + this.id, person, jwtTokenConfig)
                    .then(response => {
                      if (response.status === 202) {
                        let index = getIndex(this.users, response.data.id)
                        this.users.splice(index, 1, response.data)
                        this.dialog = false
                        this.firstname = ''
                        this.lastname = ''
                        this.secname = ''
                        this.personEmail = ''
                        this.password = ''
                        this.active = ''
                        this.userEmail = ''
                        this.username = ''
                        this.roles = []
                        this.personGroups = []
                        this.inHall = false
                        this.inOnline = false
                        this.id = ''
                      } else {
                        alert("что то пошло не так")
                      }
                      console.log(response)

                    })
                    .catch(function (error) {
                      console.log(error)
                      if (error.response.status === 401) {
                        this.$store.dispatch('authentication/logout')
                        this.$store.state.alert.clear
                        this.$store.state.user.clear
                        router.push('/').then(r => {
                          console.log("error: " + r)
                        })
                      }
                    })
              } else {

                this.axios.post(apiUrl + '/user', person, jwtTokenConfig)
                    .then(response => {
                      if (response.status === 201) {
                        this.users.push(response.data)
                        this.dialog = false
                        this.firstname = ''
                        this.lastname = ''
                        this.secname = ''
                        this.personEmail = ''
                        this.password = ''
                        this.active = ''
                        this.userEmail = ''
                        this.username = ''
                        this.roles = []
                        this.personGroups = []
                        this.inHall = false
                        this.inOnline = false
                      } else {
                        alert("что то пошло не так")
                      }
                      console.log(response)

                    })
                    .catch(function (error) {
                      console.log(error)
                      if (error.response.status === 401) {
                        this.$store.dispatch('authentication/logout')
                        this.$store.state.alert.clear
                        this.$store.state.user.clear
                        router.push('/').then(r => {
                          console.log("error: " + r)
                        })
                      }
                    })
              }
            }
          }
      )

    },
    editUser: function (event) {
      this.id = event.id
      this.firstname = event.firstname
      this.lastname = event.lastname
      this.secname = event.secname
      this.personEmail = event.personEmail
      this.active = event.active
      this.userEmail = event.userEmail
      this.username = event.username
      this.roles = event.roles
      this.personGroups = event.personGroups
      this.dialog = true
      this.inOnline = event.inOnline
      this.inHall = event.inHall
    },
    deleteUser: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.axios
          .delete(apiUrl + '/user/' + item.id, jwtTokenConfig)
          .then(response => {
            if (response.status === 202) {
              this.users.splice(this.users.indexOf(item), 1)
            } else {
              console.log(response)
            }
          })
          .catch(function (error) {
            console.log(error)
            if (error.response.status === 401) {
              router.push('/login').then(r => {
                console.log("error: " + r)
              })
            }
          })
    },
    userGroupFilter(item, queryText) {
      console.log("filter item: " + item)
      const textPersonGroupName = item.personGroupName.toLowerCase()
      const textPersonGroupDescription = item.personGroupDescription.toLowerCase()

      const searchText = queryText.toLowerCase()

      return textPersonGroupName.indexOf(searchText) > -1 ||
          textPersonGroupDescription.indexOf(searchText) > -1

    },
  },


    computed: {
      personGroupsSource() {
        let tempGroups = this.userGroups
        return tempGroups.filter(item => item.isLocked === false);

      },

      filteredUserGroups() {

        const ss = this.groupSearchBody.toLowerCase();

        let tempGroups = this.userGroups

        return tempGroups.filter(item =>
            _.some(item, v => {
              if (!(v instanceof Object)) {
                return _.toLower(v).indexOf(ss) > -1
              }

            }));
      },
      sortedUserGroups() {
        //const k = this.sortKey;
        let tempGroups = this.filteredUserGroups
        return tempGroups.sort(dynamicSort(this.userGroupSortKey, this.userGroupSortOrder));
      },
      groupPageCount() {
        let l = this.filteredUserGroups.length,
            s = parseInt(this.noteCount);
        console.log("Page count: " + Math.ceil(l / s))

        return Math.ceil(l / s);
      },
      groupsPaginatedData() {
        const start = (this.currentGroupPage - 1) * parseInt(this.noteCount),
            end = start + parseInt(this.noteCount);

        return this.sortedUserGroups.slice(start, end);
      },
      currentGroupPage() {
        if (this.groupPageCount < this.groupPage) {
          return 1
        } else {
          return this.groupPage
        }
      },


      filteredUsers() {

        const ss = this.searchBody.toLowerCase();
        let tempUsers = this.users

        return tempUsers.filter(item =>
            _.some(item, v => {
              if (!(v instanceof Object)) {
                return _.toLower(v).indexOf(ss) > -1
              }

            }));
      },
      sortedUsers() {
        let tempUsers = this.filteredUsers
        return tempUsers.sort(dynamicSort(this.userSortKey, this.userSortOrder));
      },
      pageCount() {
        let l = this.filteredUsers.length,
            s = parseInt(this.noteCount);
        console.log("Page count: " + Math.ceil(l / s))
        return Math.ceil(l / s);
      },
      paginatedData() {
        const start = (this.currentPage - 1) * parseInt(this.noteCount),
            end = start + parseInt(this.noteCount);
        return this.sortedUsers.slice(start, end);
      },
      currentPage() {
        if (this.pageCount < this.page) {
          return 1
        } else {
          return this.page
        }
      },

      loggedIn() {
        return this.$store.state.authentication.status.loggedIn
      },
      user() {
        return this.$store.state.authentication.user
      },
      authentication() {
        return this.$store.state.authentication
      },

    },
    created: function () {
      this.showLoader = true
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      function getUsers(jwtTokenConfig, axios) {
        return axios.get(apiUrl + '/user', jwtTokenConfig)
      }

      function getUserGroups(jwtTokenConfig, axios) {
        return axios.get(apiUrl + '/user_group', jwtTokenConfig)
      }

      Promise.all([
        getUsers(jwtTokenConfig, this.axios),
        getUserGroups(jwtTokenConfig, this.axios),

      ])
          .then(results => {

            if (results[0].status === 200) {
              this.users = results[0].data;
              console.log("size users: " + this.users.length)
            } else if (results[0].status === 401) {
              router.push('/login').then(r => {
                console.log("error: " + r)
              })
            }
            if (results[1].status === 200) {
              this.userGroups = results[1].data;
              console.log("size userGroups: " + this.userGroups.length)
            } else if (results[1].status === 401) {
              router.push('/login').then(r => {
                console.log("error: " + r)
              })
            }
            this.showLoader = false
          });
    },

}

function dynamicSort(property, sortOrder) {
  return function (a,b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

</script>
<style>

</style>
