<template>
  <v-container >
    <v-card class="mx-auto ma-10">

      <v-tabs v-model="part" background-color="primary">
        <v-tab  value="0">О разделе</v-tab>
        <v-tab @click="part=1" value="1">Опросы</v-tab>
        <v-tab @click="part=2" value="2">Типы опросов</v-tab>

      </v-tabs>
      <v-card-text class="align-self-stretch ma-0 pa-0">
        <v-window v-model="part" class="align-self-stretch h-75" >
          <v-window-item value="0" class="align-self-stretch" >
            <v-card-title class="card_ligth_header">
              <h3 class="ma-2">О разделе</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="min-height: 80vh;">
              <p>Данный раздел позволяет создавать шаблоны (типы) для создания опросов и формировать опросы на основе данных шаблонов.</p>

            </v-card-text>
            <v-divider></v-divider>

          </v-window-item>
          <v-window-item :value="1" class="align-self-stretch" >
            <v-card-title class="card_ligth_header">
              <h3 class="ma-2">Опросы</h3>
            </v-card-title>
            <v-card-title class="card_ligth_header" style="min-height: 5vh;">
              <v-row>
                <v-col cols=12>
                  <v-text-field
                      v-model="checklistSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                      append-icon="mdi-plus-circle-outline"
                      @click:append="checklistDialog=true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="min-height: 80vh;">
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="(item) in checklistPaginatedData" :key="item.id">
                  <v-card-title class="card_ligth_header">
                    <h5>№ {{sortedChecklists.indexOf(item) +1}}</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text >
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{item.checklistDescription}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Тип:</v-list-item-title>
                        <v-list-item-subtitle v-if="item.checklistType!==null">{{item.checklistType.typeName}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Создан:</v-list-item-title>
                        <v-list-item-subtitle >{{(item.createDate).replace('T', ' ')}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>


                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="card_ligth_actions">
                    <v-btn @click="editChecklistSubjects(item)"   style="margin: 5px;" size="small" mx-1 variant="outlined" color="primary" rounded><font-awesome-icon icon="fa-solid fa-users-gear" /></v-btn>
                    <v-btn @click="checklistCounters(item)"   style="margin: 5px;" size="small" mx-1 variant="outlined" color="primary" rounded><font-awesome-icon  icon="fa-solid fa-chart-line" /></v-btn>
                    <v-btn @click="editChecklist(item)"   style="margin: 5px;" size="small" mx-1 variant="outlined" color="primary" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                    <v-btn color="warning"  @click="deleteChecklist(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                  </v-card-actions>
                </v-card>

              </div>
              <div class="d-none d-sm-block">
                <v-table
                    fixed-header
                    class="h-100"
                >
                  <thead>
                  <tr>
                    <th class="text-left">
                      <b># </b>
                      <font-awesome-icon v-on:click="byChecklistId" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Название </b>
                      <font-awesome-icon v-on:click="byСhecklistName" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Описание </b>
                      <font-awesome-icon v-on:click="byChecklistDescription" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Создан </b>
                      <font-awesome-icon v-on:click="byChecklistType" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Тип </b>
                      <font-awesome-icon v-on:click="byCreateDate" icon="fa-solid fa-sort" />
                    </th>

                    <th class="text-left">
                      <b>Действие</b>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in checklistPaginatedData" :key="item.id" >
                    <td><p>{{sortedChecklists.indexOf(item) +1}}</p></td>
                    <td><p>{{item.name}}</p></td>
                    <td><p>{{item.checklistDescription}}</p></td>
                    <td><p>{{(item.createDate).replace('T', ' ')}}</p></td>
                    <td><p v-if="item.checklistType!==null">{{item.checklistType.typeName}}</p></td>

                    <td>
                      <p>
                        <v-btn @click="editChecklistSubjects(item)"   style="margin: 5px;" size="small" mx-1 variant="outlined" color="primary" rounded><font-awesome-icon icon="fa-solid fa-users-gear" /></v-btn>
                        <v-btn @click="checklistCounters(item)"   style="margin: 5px;" size="small" mx-1 variant="outlined" color="primary" rounded><font-awesome-icon icon="fa-solid fa-chart-line" /></v-btn>
                        <v-btn @click="editChecklist(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" color="primary" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                        <v-btn color="warning" @click="deleteChecklist(item)" style="margin: 5px;" size="small" variant="outlined"  rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_header" style="min-height: 5vh;"  >
              <v-container class="text-center">
                <v-pagination
                    v-model="checklistPage"
                    :length="checklistPageCount"
                    :total-visible="3"
                    size="small"
                ></v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
          <v-window-item :value="2" class="align-self-stretch"  >
            <v-card-title class="card_ligth_header">
              <h3 class="ma-2">Типы опросов</h3>
            </v-card-title>
            <v-card-title class="card_ligth_header" style="min-height: 5vh;">
              <v-row>
                <v-col cols=12>
                  <v-text-field
                      v-model="checklistTypeSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                      append-icon="mdi-plus-circle-outline"
                      @click:append="checklistTypeDialog=true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="min-height: 80vh;">
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="(item) in checklistTypePaginatedData" :key="item.id">
                  <v-card-title class="card_ligth_header">
                    <h5> Тип № {{sortedChecklistTypes.indexOf(item) +1}}</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text >
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{item.typeName}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Вопросов:</v-list-item-title>
                        <v-list-item-subtitle>{{item.criteriaCount}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Вопросы:</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip-group active-class="primary--text"   column>
                            <v-chip  size="small" v-for="pg in item.criteriaSet" :key="pg" >{{(pg.criteriaBody).substring(0,10)}}...</v-chip>
                          </v-chip-group>
                        </v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Создан:</v-list-item-title>
                        <v-list-item-subtitle >{{(item.createDate).replace('T', ' ')}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>

                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Активен:</v-list-item-title>
                        <v-list-item-subtitle><v-chip size="small" v-if="item.isModerated">Да</v-chip><v-chip v-else size="small">Нет</v-chip></v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="card_ligth_actions">
                    <v-btn color="primary" @click="checklistCriteria(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-circle-question" /></v-btn>
                    <v-btn color="primary" @click="editChecklistType(item)"   style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                    <v-btn  color="warning" @click="deleteChecklistType(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                  </v-card-actions>
                </v-card>

              </div>
              <div class="d-none d-sm-block">
                <v-table
                    fixed-header
                    class="h-100"
                >
                  <thead>
                  <tr>
                    <th class="text-left">
                      <b># </b>
                      <font-awesome-icon v-on:click="byChecklistTypeId" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Название </b>
                      <font-awesome-icon v-on:click="byСhecklistTypeName" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Добавлено </b>
                      <font-awesome-icon v-on:click="byChecklistTypeCreateDate" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Вопросов </b>
                      <font-awesome-icon v-on:click="byChecklistTypeCriteriaCount" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Вопросы </b>
                    </th>

                    <th class="text-left">
                      <b>Активно </b>
                      <font-awesome-icon v-on:click="byChecklistTypeIsActive" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Действие</b>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in checklistTypePaginatedData" :key="item.id" >
                    <td><p>{{sortedChecklistTypes.indexOf(item) +1}}</p></td>
                    <td><p>{{item.typeName}}</p></td>
                    <td><p>{{(item.createDate).replace('T', ' ')}}</p></td>
                    <td><p>{{item.criteriaCount}}</p></td>
                    <td>
                      <v-chip-group active-class="primary--text" column>
                        <v-chip size="small" v-for="pg in item.criteriaSet" :key="pg">{{(pg.criteriaBody).substring(0,10)}}...</v-chip>
                      </v-chip-group>
                    </td>
                    <td><v-chip size="small" v-if="item.complete">Да</v-chip><v-chip v-else size="small">Нет</v-chip></td>
                    <td>
                      <p>
                        <v-btn color="primary" @click="checklistCriteria(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-circle-question" /></v-btn>
                        <v-btn color="primary" @click="editChecklistType(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                        <v-btn color="warning" @click="deleteChecklistType(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_header" style="min-height: 5vh;"  >
              <v-container class="text-center">
                <v-pagination
                    v-model="checklistTypePage"
                    :length="checklistTypePageCount"
                    :total-visible="3"
                    size="small"
                ></v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
          <v-window-item :value="3" class="align-self-stretch"  >
            <v-card-title class="card_ligth_header">
              <v-row>
                <v-col cols=1 >
                  <v-btn
                      class="ma-2"
                      size="x-small"
                      variant="outlined"
                      icon
                      @click="backPart"
                  >
                    <font-awesome-icon  icon="fa-solid fa-arrow-left" />
                  </v-btn>
                </v-col>
                <v-col cols=11>
                  <h3 class="ma-2">Вопросы</h3>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title class="card_ligth_header" style="min-height: 5vh;">
              <v-row>
                <v-col cols=12>
                  <v-text-field
                      v-model="criteriaSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                      append-icon="mdi-plus-circle-outline"
                      @click:append="criteriaDialog=true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="min-height: 80vh;">
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="(item) in criteriaPaginatedData" :key="item.id">
                  <v-card-title class="card_ligth_header">
                    <h5> Вопрос № {{sortedCriterias.indexOf(item) +1}}</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text >
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Вопрос:</v-list-item-title>
                        <v-list-item-subtitle>{{item.criteriaBody}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Ответов:</v-list-item-title>
                        <v-list-item-subtitle>{{item.optionsCount}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Ответы:</v-list-item-title>
                        <v-list-item-subtitle >
                          <v-chip-group active-class="primary--text" column>
                            <v-chip size="small" v-for="pg in item.options" :key="pg">{{(pg.optionBody).substring(0,10)}}...</v-chip>
                          </v-chip-group>
                        </v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="card_ligth_actions">
                    <v-btn color="primary" @click="criteriaOption(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-check" /></v-btn>
                    <v-btn color="primary" @click="editCriteria(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                    <v-btn color="warning" @click="deleteCriteria(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                  </v-card-actions>
                </v-card>

              </div>
              <div class="d-none d-sm-block">
                <v-table
                    fixed-header
                    class="h-100"
                >
                  <thead>
                  <tr>
                    <th class="text-left">
                      <b># </b>
                      <font-awesome-icon v-on:click="byCriteriaId" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Вопрос </b>
                      <font-awesome-icon v-on:click="byCriteriaBody" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Ответов</b>
                      <font-awesome-icon v-on:click="byCriteriaOptionsCount" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Ответы</b>
                    </th>
                    <th class="text-left">
                      <b>Действие</b>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in criteriaPaginatedData" :key="item.id" >
                    <td><p>{{sortedCriterias.indexOf(item) +1}}</p></td>
                    <td><p>{{item.criteriaBody}}</p></td>
                    <td><p>{{item.optionsCount}}</p></td>
                    <td>
                      <v-chip-group active-class="primary--text" column>
                        <v-chip size="small" v-for="pg in item.options" :key="pg">{{(pg.optionBody).substring(0,10)}}...</v-chip>
                      </v-chip-group>
                   </td>
                    <td>
                      <p>
                        <v-btn color="primary" @click="criteriaOption(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-check" /></v-btn>
                        <v-btn color="primary" @click="editCriteria(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                        <v-btn color="warning" @click="deleteCriteria(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_header" style="min-height: 5vh;"  >
              <v-container class="text-center">
                <v-pagination
                    v-model="criteriaPage"
                    :length="criteriaPageCount"
                    :total-visible="3"

                    size="small"
                ></v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
          <v-window-item :value="4" class="align-self-stretch"  >
            <v-card-title class="card_ligth_header">
              <v-row>
                <v-col cols=1 >
                  <v-btn
                      class="ma-2"
                      size="x-small"
                      variant="outlined"
                      icon
                      @click="backPart"
                  >
                    <font-awesome-icon  icon="fa-solid fa-arrow-left" />
                  </v-btn>
                </v-col>
                <v-col cols=11>
                  <h3 class="ma-2">Варианты ответа на вопрос</h3>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title class="card_ligth_header" style="min-height: 5vh;">
              <v-row>
                <v-col cols=12>
                  <v-text-field
                      v-model="optionSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                      append-icon="mdi-plus-circle-outline"
                      @click:append="criteriaOptionDialog=true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="min-height: 80vh;">
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="(item) in optionPaginatedData" :key="item.id">
                  <v-card-title class="card_ligth_header">
                    <h5>№ {{sortedOptions.indexOf(item) +1}}</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text >
                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Ответ:</v-list-item-title>
                        <v-list-item-subtitle>{{item.optionBody}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>

                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="card_ligth_actions">
                    <v-btn color="primary" @click="editOption(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                    <v-btn color="warning" @click="deleteOption(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                  </v-card-actions>
                </v-card>

              </div>
              <div class="d-none d-sm-block">
                <v-table
                    fixed-header
                    class="h-100"
                >
                  <thead>
                  <tr>
                    <th class="text-left">
                      <b># </b>
                      <font-awesome-icon v-on:click="byOptionId" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Ответ </b>
                      <font-awesome-icon v-on:click="byOptionBody" icon="fa-solid fa-sort" />
                    </th>

                    <th class="text-left">
                      <b>Действие</b>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in optionPaginatedData" :key="item.id" >
                    <td><p>{{sortedOptions.indexOf(item) +1}}</p></td>
                    <td><p>{{item.optionBody}}</p></td>
                    <td>
                      <p>
                        <v-btn color="primary" @click="editOption(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                        <v-btn color="warning" @click="deleteOption(item)" style="margin: 5px;" size="small" variant="outlined" rounded><font-awesome-icon icon="fas fa-trash-alt"/></v-btn>
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions" style="min-height: 5vh;"  >
              <v-container class="text-center">
                <v-pagination
                    v-model="optionPage"
                    :length="optionPageCount"
                    :total-visible="3"
                    size="small"
                ></v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
          <v-window-item :value="5" class="align-self-stretch"  >
            <v-card-title class="card_ligth_header">
              <v-row>
                <v-col cols=1 >
                  <v-btn
                      class="ma-2"
                      size="x-small"
                      variant="outlined"
                      icon
                      @click="backToChecklists"
                  >
                    <font-awesome-icon  icon="fa-solid fa-arrow-left" />
                  </v-btn>
                </v-col>
                <v-col cols=11>
                  <h3 class="ma-2">Ход анкетирования</h3>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title class="card_ligth_header" style="min-height: 5vh;">
              <v-row>
                <v-col cols=12>
                  <v-text-field
                      v-model="checklistSubjectSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                      append-icon="mdi-plus-circle-outline"
                      @click:append="checklistSubjectDialog=true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="min-height: 80vh;">
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card  variant="tonal" class="ma-5 mx-5 w-100" v-for="(item) in checklistSubjectPaginatedData" :key="item.id">
                  <v-card-title class="card_ligth_header">
                    <h5>Анкета  № {{sortedСhecklistSubject.indexOf(item) +1}}</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text >

                    <v-list-item lines="two">
                      <v-list-item-header>
                        <v-list-item-title>Добавлено:</v-list-item-title>
                        <v-list-item-subtitle>{{(item.createDate).replace('T', ' ')}}</v-list-item-subtitle>
                      </v-list-item-header>
                    </v-list-item>

                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="card_ligth_actions">
                    <v-btn color="primary" @click="showSubjectOptions(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>

                  </v-card-actions>
                </v-card>

              </div>
              <div class="d-none d-sm-block">
                <v-table
                    fixed-header
                    class="h-100"
                >
                  <thead>
                  <tr>
                    <th class="text-left">
                      <b># </b>
                      <font-awesome-icon v-on:click="bySubjectId" icon="fa-solid fa-sort" />
                    </th>
                    <th class="text-left">
                      <b>Добавлено</b>
                      <font-awesome-icon v-on:click="bySubjectAddeded" icon="fa-solid fa-sort" />
                    </th>

                    <th class="text-left">
                      <b>Действие</b>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in checklistSubjectPaginatedData" :key="item.id" >
                    <td><p>{{sortedСhecklistSubject.indexOf(item) +1}}</p></td>
                    <td><p>{{(item.createDate).replace('T', ' ')}}</p></td>
                    <td>
                      <p>
                        <v-btn color="primary" @click="showSubjectOptions(item)"  style="margin: 5px;" size="small" mx-1 variant="outlined" rounded><font-awesome-icon icon="fa-solid fa-pen-to-square" /></v-btn>
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="card_ligth_actions" style="min-height: 5vh;"  >
              <v-container class="text-center">
                <v-pagination
                    v-model="checklistSubjectPage"
                    :length="checklistSubjectPageCount"
                    :total-visible="3"
                    size="small"
                ></v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
    <v-row justify="center">
      <v-dialog
          v-model="checklistDialog"
          scrollable
          persistent
      >
        <v-card   max-width="500"
                  min-width="350">
          <v-card-title class="card_ligth_header">
            <h5>Тип опроса</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="checklistForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Название*"
                        v-model="name"
                        :rules="checklistNameRules"
                        variant="outlined"
                        hint="Укажите название достижения"
                        :counter="30"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        label="описание*"
                        v-model="checklistDescription"
                        :rules="checklistDescriptionRules"
                        variant="outlined"
                        :counter="255"
                        hint="Укажите описание"
                        required
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                        v-model="checklistType"
                        :items="checklistTypes"
                        outlined
                        dense
                        chips
                        small-chips
                        closable-chips
                        label="Тип"
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                            v-bind="props"
                            :text="item.raw.typeName"
                        ></v-chip>
                      </template>
                      <template v-slot:item="{ props, item }">

                        <v-list-item
                            v-bind="props"
                            :title="item.raw.typeName"
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                @click="closeChecklist"
            >
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                @click="saveChecklist"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="checklistTypeDialog"
          scrollable
          persistent
      >
        <v-card   max-width="500"
                  min-width="350">
          <v-card-title class="card_ligth_header">
            <h5>Тип опроса</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="checklistTypeForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Название*"
                        v-model="typeName"
                        :rules="typeNameRules"
                        variant="outlined"
                        hint="Укажите название"
                        :counter="50"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                        v-model="criteriaCount"
                        :items="questionsCounter"
                        dense
                        filled
                        label="критериев"
                    ></v-autocomplete>
                  </v-col>


                  <v-col cols="12">
                    <v-row
                        class="ma-auto"
                        fluid
                    >
                      <v-switch
                          v-model="complete"
                          :label="`Статус`"
                          color="success"
                      >
                      </v-switch>
                    </v-row>
                  </v-col>

                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                @click="closeChecklistType"
            >
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                @click="saveChecklistType"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="criteriaDialog"
          scrollable
          persistent
      >
        <v-card   max-width="500"
                  min-width="350">
          <v-card-title class="card_ligth_header">
            <h5>Вопрос</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="questionForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        label="Вопрос*"
                        v-model="criteriaBody"
                        :rules="criteriaBodyRules"
                        variant="outlined"
                        :counter="1000"
                        hint="Укажите вопрос"
                        required
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="optionsCount"
                        :items="answersCounter"
                        dense
                        filled
                        label="Кол-во ответов"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-row
                        class="ma-auto"
                        fluid
                    >
                      <v-switch
                          v-model="multipleAnswers"
                          :label="`Несколько ответов`"
                          color="success"
                      >
                      </v-switch>
                      <v-chip v-if="complete"> Готово к использованию</v-chip><v-chip v-else> Не готово  к использованию</v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                @click="closeCriteria"
            >
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                @click="saveCriteria"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="criteriaOptionDialog"
          scrollable
          persistent
      >
        <v-card max-width="500"
                min-width="350">
          <v-card-title class="card_ligth_header">
            <h5>Ответ</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="criteriaOptionForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        label="Описание*"
                        v-model="optionBody"
                        :rules="optionBodyRules"
                        variant="outlined"
                        :counter="1000"
                        hint="Укажите описание"
                        required
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-row
                        class="ma-auto"
                        fluid
                    >
                      <v-switch
                          v-model="freeAnswer"
                          :label="`Свободный ответ`"
                          color="success"
                      >
                      </v-switch>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                @click="closeOption"
            >
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                @click="saveOption"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="checklistSubjectDialog"
          scrollable
          persistent
      >
        <v-card max-width="500"
                min-width="350">
          <v-card-title class="card_ligth_header">
            <h5>Субьект оценочного листа</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="subjectForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="checklistSubject"
                        :items="childs"
                        :custom-filter="childFilter"
                        outlined
                        dense
                        chips
                        small-chips
                        closable-chips
                        item-title="firstname"
                        item-value="id"
                        required
                        :rules="subjectRules"
                        label="Критерии"
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                            v-bind="props"
                            :text="item.raw.firstname +' '+item.raw.secname+' '+item.raw.lastname"
                        ></v-chip>
                      </template>
                      <template v-slot:item="{ props, item }">

                        <v-list-item
                            v-bind="props"
                            :title="item.raw.firstname +' '+item.raw.secname+' '+item.raw.lastname"
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                @click="closeSubject"
            >
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                @click="saveSubject"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="checklistSubjectCriteriaDialog"
          scrollable
          persistent
      >
        <v-card max-width="500"
                min-width="350">
          <v-card-title class="card_ligth_header">
            <h5>Анкетирование от {{selectedSubject.createDate}}</h5>
          </v-card-title>
          <v-card-text>
              <v-container>
                <v-row>
                  <v-container >
                    <v-row>
                      <v-col>
                        <v-card v-for="(item, index) in sortedCretarias" :key="index" class="ma-6" min-width="600px;">
                          <v-card-title class="card_ligth_header">
                            <span class="text-h6 left">{{item.criteria.criteriaBody}}</span>
                          </v-card-title>
                          <v-divider></v-divider>

                          <v-card-text v-for="(itemL, findex) in resultSortedOptions(item)" :key="findex" class="text-left">



                            <v-row  justify="end">
                              <v-col cols="2"  md="2"  sm="2" xl="2" lg="2" style="display: flex; align-items: center;"
                              >
                                <v-switch style="display: flex; align-items: center;"
                                          color="primary"
                                          v-model="itemL.checked"

                                ></v-switch>
                              </v-col>
                              <v-col cols="10"  md="10"  sm="10" xl="10" lg="10" style="display: flex; align-items: center;">
                                {{itemL.optionBody}}
                              </v-col>
                              <v-card class="w-100" v-if="((itemL.checked)&&(itemL.freeAnswer))">
                                <v-card-text >
                                  <v-col cols="12">
                                    <v-textarea
                                        label="Ваш вариант ответа*"
                                        v-model="itemL.feeOptionBody"
                                        :rules="optionBodyRules"

                                        variant="outlined"
                                        :counter="1000"
                                        hint="Введите Ваш вариант ответа и нажмите на конопку сохранить"
                                        required
                                    ></v-textarea>
                                  </v-col>
                                </v-card-text>
                                <v-card-actions class="card_ligth_actions" >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      rounded
                                      variant="outlined"
                                      >
                                    Сохранить
                                  </v-btn>
                                </v-card-actions>

                              </v-card>


                            </v-row>

                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                @click="checklistSubjectCriteriaDialog=false"
            >
              Закрыть
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="showChecklistCounters"
          scrollable
          persistent
      >
        <v-card max-width="1080"
                min-width="350">
          <v-card-title class="card_ligth_header">
            <h5>Статистика анкетирования</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-container >
                  <v-row>
                    <v-col>
                      <v-card v-for="(item, index) in counters" :key="index" class="ma-6" min-width="600px;">
                        <v-card-title class="card_ligth_header">
                          <span class="text-h6 left">{{item.criteriaBody}}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text v-for="(itemL, findex) in statSortedOptions(item.options)" :key="findex">
                          <v-row  class="text-left"  justify="end">

                            <v-col cols="10"  md="10"  sm="10" xl="11" lg="11" style="display: flex; align-items: center;">
                              {{itemL.optionBody}}
                            </v-col>
                            <v-col cols="2"  md="2"  sm="2" xl="1" lg="1" style="display: flex; align-items: center;"
                            >
                              <span class="text-h6">{{itemL.optionsCount}}</span>
                            </v-col>
                            <v-col v-if="itemL.freeAnswers.length>0" cols="2"  md="2"  sm="2" xl="1" lg="1" style="display: flex; align-items: center;"
                            >

                            </v-col>

                          </v-row>
                          <v-row v-for="(itemLL, ffindex) in itemL.freeAnswers" :key="ffindex">
                            <v-col cols="12" >
                              <v-alert v-if="itemLL!==null" color="success">
                              {{itemLL}}
                              </v-alert>
                            </v-col>
                          </v-row>



                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn
                rounded
                variant="outlined"
                @click="showChecklistCounters=false"
            >
              Закрыть
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="statLoader"
          persistent
      ><v-alert><span class="text-h5 text-primary">Загрузка данных <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular></span></v-alert>

      </v-dialog>


    </v-row>
  </v-container>
</template>

<script>
import {router} from "@/service/router";
import {apiUrl} from "@/service/user.service"

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}
import _ from 'lodash';


export default {
  name: 'AdminChecklist',

  props: [],
  data: () => ({
    checklistTypes: [],

    typeName: '',
    criteriaCount: null,
    complete: false,
    multipleAnswers: false,
    freeAnswer: false,

    criteriaSet:[],
    checklistTypeDialog:false,
    checklistTypeId:'',
    typeNameRules:[
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    checklistTypeSearchBody:'',
    checklistTypeSortKey: "createDate",
    lastChecklistTypeSortKey: "createDate",
    checklistSortTypeOrder: 1,
    checklistTypePage: 1,
    selectedChecklistType: '',
    checklistTypeCriterias:[],
    criteriaSearchBody: '',
    criteriaDialog:false,

    criteriaSortKey: "createDate",
    lastCriteriaTypeSortKey: "createDate",
    criteriaSortOrder: 1,

    criteriaPage: 1,

    criteriaBodyRules:[
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 1000) || 'Значение до должно превышать 1000 символов!',
    ],
    criteriaId:'',
    criteriaBody:'',
    optionsCount:'',
    options:[],

    optionBody:'',
    optionBodyRules:
        [
          v => !!v || 'Поле обязательно для заполнения!',
          v => (v && v.length <= 10000) || 'Значение до должно превышать 1000 символов!',
        ],
    criteriaOptionDialog: false,
    selectedOption:'',
    optionId:'',
    selectedCriteria:'',

    optionSearchBody:'',
    optionPage:1,

    optionSortKey: "createDate",
    lastOptionTypeSortKey: "createDate",
    optionSortOrder: 1,







    userGroups: [],
    personGroups:null,
    part:0,
    checklistDialog: false,
    name:'',
    checklistName: '',
    checklistType:null,
    checklistNameRules:[
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 30) || 'Значение до должно превышать 30 символов!',
    ],
    checklistDescription:'',
    checklistDescriptionRules:[
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 255) || 'Значение до должно превышать 30 символов!',
    ],
    isActive: false,

    questionCount: null,

    questionDialog:false,

    questionBody: '',
    questionBodyRules:[
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 255) || 'Значение до должно превышать 30 символов!',
    ],

    answerCount: null,

    questionOrder:null,
    answerDialog: false,

    answerBody:'',
    answerBodyRules:[
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 255) || 'Значение до должно превышать 30 символов!',
    ],

    isTrue:false,
    checklistId: null,
    answerId: null,
    questionId: null,
    checklists:[],
    selectedChecklist:null,
    questions:[],

    selectedQuestion: null,
    checklistSearchBody: '',
    checklistPage: 1,
    checklistSortKey: "createDate",
    lastChecklistSortKey: "createDate",
    checklistSortOrder: 1,


    noteCount:20,

    questionsSearchBody:'',
    questionPage: 1,
    questionSortKey: "id",
    lastQuestionSortKey: "id",
    questionSortOrder: 1,

    answers:[],
    answerSearchBody:'',
    answerPage:1,
    answerSortKey: "id",
    lastAnswerSortKey: "id",
    answerSortOrder: 1,
    selectedAnswer: null,

    checklistSubjects:[],
    checklistSubjectSearchBody:'',
    checklistSubjectDialog: false,
    checklistSubjectPage:1,
    checklistSubjectSortKey: "id",
    lastChecklistSubjectSortKey: "id",
    checklistSubjectSortOrder: 1,
    checklistSubject: null,
    childs:[],
    showChecklistCounters:false,
    counters:[],

    subjectRules: [
      v => (!!v && true)  || 'Поле обязательно для заполнения!'
    ],

    selectedSubject:null,
    checklistSubjectCriteriaDialog:false,
    statLoader: false,





  }),
  //watch: {
  //  part(newPart) {
  //    localStorage.cognationAdminTab = newPart;
  //  }
  //},
  //mounted() {
  //  if (localStorage.cognationAdminTab) {
  //    this.part = localStorage.cognationAdminTab;
  //  }
  //},
  methods: {
    checklistCounters(checklist){
      this.statLoader = true;

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }


      this.axios.get(apiUrl +'/internal/checklist/counters/' + checklist.id, jwtTokenConfig)
          .then(response => {
            if (response.status === 200) {

              this.counters = response.data
              this.statLoader = false
              this.showChecklistCounters = true

              // let index = getIndex(this.selectedSubject.cretarias, response.data.id)
              // this.selectedSubject.cretarias.splice(index, 1, response.data)
            } else {
              alert("что то пошло не так")
            }
            console.log(response)

          })
          .catch(function (error) {
            console.log(error)
            if(error.response.status===401){
              router.push('/login').then(r => {console.log("error: " + r)})
            }
          })
    },

    subjectCriteriaUpdate(item, index){
      if(index!==null){
        console.log("index: " + index)
        console.log("criteria criteriaBody: " + item.criteria.criteriaBody)
        if(item.option!==null){
          console.log("selected option: " + item.option.optionBody)
        }

        let jwtTokenConfig = {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        }

        let option = {
          optionId: index,

        }


        this.axios.put(apiUrl +'/internal/checklist/subject/criteria/' + item.id, option, jwtTokenConfig)
            .then(response => {
              if (response.status === 202) {
               // let index = getIndex(this.selectedSubject.cretarias, response.data.id)
               // this.selectedSubject.cretarias.splice(index, 1, response.data)
              } else {
                alert("что то пошло не так")
              }
              console.log(response)

            })
            .catch(function (error) {
              console.log(error)
              if(error.response.status===401){
                router.push('/login').then(r => {console.log("error: " + r)})
              }
            })




      }

    },

    backToChecklists(){
      this.part=1
    },
    closeChecklistType(){
      this.typeName = ''
      this.criteriaCount = null
      this.complete = false
      this.criteriaSet=null
      this.checklistTypeDialog=false
    },

    saveChecklistType(){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.$refs.checklistTypeForm.validate().then(response =>{
        if(response.valid){
          console.log("criteriaCount: " + this.criteriaCount)
          let checklistType = {
            typeName: capitalizeFirstLetter(this.typeName),
            criteriaCount: this.criteriaCount,
            complete: this.complete,
            criteriaSet: this.criteriaSet,
          }
          if(this.checklistTypeId){
            this.axios.put(apiUrl +'/internal/checklist/type/' + this.checklistTypeId, checklistType, jwtTokenConfig)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.checklistTypes, response.data.id)
                    this.checklistTypes.splice(index, 1, response.data)
                    this.checklistTypeId = null
                    this.typeName = ''
                    this.criteriaCount = null
                    this.criteriaSet = null
                    this.complete=false
                    this.checklistTypeDialog=false

                  } else {
                    alert("что то пошло не так")
                  }
                  console.log(response)

                })
                .catch(function (error) {
                  console.log(error)
                  if(error.response.status===401){
                    router.push('/login').then(r => {console.log("error: " + r)})
                  }
                })

          } else {
            this.axios.post(apiUrl +'/internal/checklist/type', checklistType, jwtTokenConfig)
                .then(response => {
                  if (response.status === 201) {
                    this.checklistTypes.push(response.data)
                    this.typeName = ''
                    this.criteriaCount = null
                    this.criteriaSet = null
                    this.complete=false
                    this.checklistTypeDialog=false
                  } else {
                    alert("что то пошло не так")
                  }
                  console.log(response)


                })
                .catch(function (error) {
                  console.log(error)
                  if(error.response.status===401){
                    router.push('/login').then(r => {console.log("error: " + r)})
                  }
                })
          }

        }

      })



    },

    editChecklistType(item){
      this.checklistTypeId = item.id
      this.typeName = item.typeName
      this.complete = item.complete
      this.criteriaCount = item.criteriaCount
      this.checklistTypeDialog = true

    },
    resultSortedOptions(options) {
      return _.orderBy(options.optionList, 'id', 'asc');
    },
    statSortedOptions(options) {
      return _.orderBy(options, 'optionsCount', 'desc');
    },

    deleteChecklistType(item){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      if(confirm("Вы точно хотите удалить запись?")){
        this.axios
            .delete(apiUrl +'/internal/checklist/type/' + item.id, jwtTokenConfig)
            .then(response => {
              if (response.status === 202) {
                this.checklistTypes.splice(this.checklistTypes.indexOf(item), 1)
              } else {
                console.log(response)
              }
            })
            .catch(function (error) {
              console.log(error)
              if(error.response.status===401){
                router.push('/login').then(r => {console.log("error: " + r)})
              }
            })
      }

    },


    backPart(){
    //  this.part = this.part -1

      if (this.part===3){
        console.log('part=2')
        this.statLoader = true

        let jwtTokenConfig = {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        }

        this.axios.get(apiUrl +'/internal/checklist/type' , jwtTokenConfig)
            .then(response => {
              if (response.status === 200) {
                this.checklistTypes =response.data
                this.statLoader = false
              } else {
                alert("что то пошло не так")
              }
              console.log(response)

            }).then(this.part = 2)
            .catch(function (error) {
              console.log(error)
              this.statLoader = false
              if(error.response.status===401){
                router.push('/login').then(r => {console.log("error: " + r)})
              }
            })

      }


      if(this.part===4){
        console.log('part=3')

        let jwtTokenConfig = {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        }

        this.axios.get(apiUrl +'/internal/checklist/type/criteria/' + this.selectedChecklistType.id, jwtTokenConfig)
            .then(response => {
              if (response.status === 200) {
                this.checklistTypeCriterias =response.data
              } else {
                alert("что то пошло не так")
              }
              console.log(response)

            }).then(this.part = 3)
            .catch(function (error) {
              console.log(error)
              if(error.response.status===401){
                router.push('/login').then(r => {console.log("error: " + r)})
              }
            })

      }

    },
    closeChecklist(){
      this.name = ''
      this.checklistDescription = ''
      this.checklistType = null
      this.checklistDialog=false
    },
    editChecklist(item){
      this.checklistId = item.id
      this.name = item.name
      this.checklistDescription = item.checklistDescription
      this.checklistType = item.checklistType
      this.checklistDialog = true
    },

    saveChecklist(){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.$refs.checklistForm.validate().then(result => {
        if(result.valid){

          let checklist = {
            name: capitalizeFirstLetter(this.name),
            checklistDescription: capitalizeFirstLetter(this.checklistDescription),
            checklistType: this.checklistType,
          }

          if(this.checklistId){
            this.axios.put(apiUrl +'/internal/checklist/' + this.checklistId, checklist, jwtTokenConfig)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.checklists, response.data.id)
                    this.checklists.splice(index, 1, response.data)
                    this.checklistId = null
                    this.name = ''
                    this.checklistDescription = ''
                    this.checklistType = null
                    this.checklistDialog=false

                  } else {
                    alert("что то пошло не так")
                  }
                  console.log(response)

                })
                .catch(function (error) {
                  console.log(error)
                  if(error.response.status===401){
                    router.push('/login').then(r => {console.log("error: " + r)})
                  }
                })

          } else {
            this.axios.post(apiUrl +'/internal/checklist', checklist, jwtTokenConfig)
                .then(response => {
                  if (response.status === 201) {
                    this.checklists.push(response.data)
                    this.name = ''
                    this.checklistDescription = ''
                    this.checklistType = null
                    this.checklistDialog=false

                  } else {
                    alert("что то пошло не так")
                  }
                  console.log(response)


                })
                .catch(function (error) {
                  console.log(error)
                  if(error.response.status===401){
                    router.push('/login').then(r => {console.log("error: " + r)})
                  }
                })
          }


        }
      })
    },

    deleteChecklist(item){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      if(confirm("Вы точно хотите удалить запись?")){
        this.axios
            .delete(apiUrl +'/internal/checklist/' + item.id, jwtTokenConfig)
            .then(response => {
              if (response.status === 202) {
                this.checklists.splice(this.checklists.indexOf(item), 1)
              } else {
                console.log(response)
              }
            })
            .catch(function (error) {
              console.log(error)
              if(error.response.status===401){
                router.push('/login').then(r => {console.log("error: " + r)})
              }
            })
      }

    },

    closeCriteria(){
      this.criteriaId = ''
      this.criteriaBody = ''
      this.optionsCount = null
      this.options = null
      this.criteriaDialog = false
      this.multipleAnswers = false
    },

    editCriteria(item){
      this.criteriaId = item.id
      this.criteriaBody = item.criteriaBody
      this.optionsCount = item.optionsCount
      this.multipleAnswers = item.multipleAnswers
      this.criteriaDialog = true
    },

    saveCriteria(){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      if (this.$refs.questionForm.validate()) {

        if(this.subject instanceof Object) this.subject= this.subject.id

        let criteria = {
          checklistTypeId: this.selectedChecklistType.id,
          criteriaBody: capitalizeFirstLetter(this.criteriaBody),
          optionsCount: this.optionsCount,
          options: this.options,
          multipleAnswers: this.multipleAnswers
        }
        if(this.criteriaId){
          this.axios.put(apiUrl +'/internal/checklist/type/criteria/' + this.criteriaId, criteria, jwtTokenConfig)
              .then(response => {
                if (response.status === 202) {
                  let index = getIndex(this.checklistTypeCriterias, response.data.id)
                  this.checklistTypeCriterias.splice(index, 1, response.data)
                  this.criteriaId = null
                  this.criteriaBody = ''
                  this.optionsCount = null
                  this.options = null
                  this.multipleAnswers = false
                  this.criteriaDialog = false
                } else {
                  alert("что то пошло не так")
                }
                console.log(response)

              })
              .catch(function (error) {
                console.log(error)
                if(error.response.status===401){
                  router.push('/login').then(r => {console.log("error: " + r)})
                }
              })

        } else {
          this.axios.post(apiUrl +'/internal/checklist/type/criteria', criteria, jwtTokenConfig)
              .then(response => {
                if (response.status === 201) {
                  this.checklistTypeCriterias.push(response.data)
                  this.criteriaBody = ''
                  this.optionsCount = null
                  this.options = null
                  this.multipleAnswers = false
                  this.criteriaDialog = false
                } else {
                  alert("что то пошло не так")
                }
              })
              .catch(function (error) {
                console.log(error)
                if(error.response.status===401){
                  router.push('/login').then(r => {console.log("error: " + r)})
                }
              })
        }
      }

    },

    deleteCriteria(item){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      if(confirm("Вы точно хотите удалить запись?")){
        this.axios
            .delete(apiUrl +'/internal/checklist/type/criteria/' + item.id, jwtTokenConfig)
            .then(response => {
              if (response.status === 202) {
                this.checklistTypeCriterias.splice(this.checklistTypeCriterias.indexOf(item), 1)
              } else {
                console.log(response)
              }
            })
            .catch(function (error) {
              console.log(error)
              if(error.response.status===401){
                router.push('/login').then(r => {console.log("error: " + r)})
              }
            })
      }

    },

    closeOption(){
      this.optionBody = ''
      this.freeAnswer = false
      this.criteriaOptionDialog = false
    },

    editOption(item){
      this.optionId = item.id
      this.optionBody = item.optionBody
      this.freeAnswer = item.freeAnswer
      this.criteriaOptionDialog = true
    },

    saveOption(){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.$refs.criteriaOptionForm.validate().then( res => {
            if(res.valid){
              let option = {
                criteriaId: this.selectedCriteria.id,
                optionBody: capitalizeFirstLetter(this.optionBody),
                freeAnswer: this.freeAnswer
              }
              if(this.optionId){
                this.axios.put(apiUrl +'/internal/checklist/type/criteria/option/' + this.optionId, option, jwtTokenConfig)
                    .then(response => {
                      if (response.status === 202) {
                        let index = getIndex(this.options, response.data.id)
                        this.options.splice(index, 1, response.data)
                        this.optionId= null
                        this.criteriaId = null
                        this.freeAnswer = false
                        this.optionBody = ''
                        this.criteriaOptionDialog = false
                      } else {
                        alert("что то пошло не так")
                      }
                      console.log(response)

                    })
                    .catch(function (error) {
                      console.log(error)
                      if(error.response.status===401){
                        router.push('/login').then(r => {console.log("error: " + r)})
                      }
                    })

              } else {
                this.axios.post(apiUrl +'/internal/checklist/type/criteria/option', option, jwtTokenConfig)
                    .then(response => {
                      if (response.status === 201) {
                        this.options.push(response.data)
                        this.optionId= null
                        this.criteriaId = null
                        this.freeAnswer = false
                        this.optionBody = ''
                        this.criteriaOptionDialog = false
                      } else {
                        alert("что то пошло не так")
                      }
                    })
                    .catch(function (error) {
                      console.log(error)
                      if(error.response.status===401){
                        router.push('/login').then(r => {console.log("error: " + r)})
                      }
                    })
              }

            }
          }
      )

    },

    deleteOption(item){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      if(confirm("Вы точно хотите удалить запись?")){
        this.axios
            .delete(apiUrl +'/internal/checklist/type/criteria/option/' + item.id, jwtTokenConfig)
            .then(response => {
              if (response.status === 202) {
                this.options.splice(this.options.indexOf(item), 1)
              } else {
                console.log(response)
              }
            })
            .catch(function (error) {
              console.log(error)
              if(error.response.status===401){
                router.push('/login').then(r => {console.log("error: " + r)})
              }
            })
      }

    },

    checklistCriteria(item){
      this.selectedChecklistType =item
      this.statLoader = true


      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.axios.get(apiUrl +'/internal/checklist/type/criteria/' + item.id, jwtTokenConfig)
          .then(response => {
            if (response.status === 200) {
              this.checklistTypeCriterias =response.data
              this.statLoader = false
            } else {
              alert("что то пошло не так")
            }
            console.log(response)

          }).then(this.part = 3)
          .catch(function (error) {
            console.log(error)
            if(error.response.status===401){
              router.push('/login').then(r => {console.log("error: " + r)})
            }
          })
      this.part = 3


    },

    editChecklistSubjects(item){
      this.selectedChecklist =item
      this.statLoader = true

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.axios.get(apiUrl +'/internal/checklist/attempt/' + item.id, jwtTokenConfig)
          .then(response => {
            if (response.status === 200) {
              this.checklistSubjects =response.data
              this.statLoader = false
            } else {
              alert("что то пошло не так")
              this.statLoader = false
            }
            console.log(response)

          }).then(this.part = 5)
          .catch(function (error) {
            console.log(error)
            if(error.response.status===401){
              router.push('/login').then(r => {console.log("error: " + r)})
            }
          })
    },

    criteriaOption(item){
      this.selectedCriteria =item
      this.statLoader = true


      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }

      this.axios.get(apiUrl +'/internal/checklist/type/criteria/option/' + item.id, jwtTokenConfig)
          .then(response => {
            if (response.status === 200) {
              this.options =response.data
              this.statLoader = false
              this.part = 4
            } else {
              alert("что то пошло не так")
              this.statLoader = false
              this.part = 4
            }
            console.log(response)

          })
          .catch(function (error) {
            console.log(error)
            if(error.response.status===401){
              router.push('/login').then(r => {console.log("error: " + r)})
            }
          })


    },

    byChecklistId(){
      if(this.lastChecklistSortKey.includes('id')){
        this.checklistSortOrder=this.checklistSortOrder*-1
      }
      this.checklistSortKey='id'
      this.lastChecklistSortKey = this.checklistSortKey

    },

    byСhecklistName(){
      if(this.lastChecklistSortKey.includes('name')){
        this.checklistSortOrder=this.checklistSortOrder*-1
      }
      this.checklistSortKey='name'
      this.lastChecklistSortKey = this.checklistSortKey
    },

    byChecklistDescription(){
      if(this.lastChecklistSortKey.includes('checklistDescription')){
        this.checklistSortOrder=this.checklistSortOrder*-1
      }
      this.checklistSortKey='checklistDescription'
      this.lastChecklistSortKey = this.checklistSortKey
    },

    byCreateDate(){
      if(this.lastChecklistSortKey.includes('createDate')){
        this.checklistSortOrder=this.checklistSortOrder*-1
      }
      this.checklistSortKey='createDate'
      this.lastChecklistSortKey = this.checklistSortKey
    },


    byChecklistType(){
      if(this.lastChecklistSortKey.includes('checklistType')){
        this.checklistSortOrder=this.checklistSortOrder*-1
      }
      this.checklistSortKey='checklistType'
      this.lastChecklistSortKey = this.checklistSortKey
    },
//lastChecklistTypeSortKey


    byChecklistTypeId(){
      if(this.lastChecklistTypeSortKey.includes('id')){
        this.checklistSortTypeOrder=this.checklistSortTypeOrder*-1
      }
      this.checklistSortKey='id'
      this.lastChecklistTypeSortKey = this.checklistSortKey
    },

    byСhecklistTypeName(){
      if(this.lastChecklistTypeSortKey.includes('typeName')){
        this.checklistSortTypeOrder=this.checklistSortTypeOrder*-1
      }
      this.checklistSortKey='typeName'
      this.lastChecklistTypeSortKey = this.checklistSortKey
    },
    byChecklistTypeCreateDate(){
      if(this.lastChecklistTypeSortKey.includes('createDate')){
        this.checklistSortTypeOrder=this.checklistSortTypeOrder*-1
      }
      this.checklistSortKey='createDate'
      this.lastChecklistTypeSortKey = this.checklistSortKey
    },
    byChecklistTypeCriteriaCount(){
      if(this.lastChecklistTypeSortKey.includes('criteriaCount')){
        this.checklistSortTypeOrder=this.checklistSortTypeOrder*-1
      }
      this.checklistSortKey='criteriaCount'
      this.lastChecklistTypeSortKey = this.checklistSortKey
    },
    byChecklistTypeIsActive(){
      if(this.lastChecklistTypeSortKey.includes('complete')){
        this.checklistSortTypeOrder=this.checklistSortTypeOrder*-1
      }
      this.checklistSortKey='complete'
      this.lastChecklistTypeSortKey = this.checklistSortKey
    },

    //
    byCriteriaId(){
      if(this.lastCriteriaTypeSortKey.includes('id')){
        this.criteriaSortOrder=this.criteriaSortOrder*-1
      }
      this.criteriaSortKey='id'
      this.lastCriteriaTypeSortKey = this.criteriaSortKey
    },

    byCriteriaBody(){
      if(this.lastCriteriaTypeSortKey.includes('criteriaBody')){
        this.criteriaSortOrder=this.criteriaSortOrder*-1
      }
      this.criteriaSortKey='criteriaBody'
      this.lastCriteriaTypeSortKey = this.criteriaSortKey
    },

    byCriteriaOptionsCount(){
      if(this.lastCriteriaTypeSortKey.includes('optionsCount')){
        this.criteriaSortOrder=this.criteriaSortOrder*-1
      }
      this.criteriaSortKey='optionsCount'
      this.lastCriteriaTypeSortKey = this.criteriaSortKey
    },
//
    byOptionId(){
      if(this.lastOptionTypeSortKey.includes('id')){
        this.optionSortOrder=this.optionSortOrder*-1
      }
      this.optionSortKey='id'
      this.lastOptionTypeSortKey = this.optionSortKey
    },

    byOptionBody(){
      if(this.lastOptionTypeSortKey.includes('optionBody')){
        this.optionSortOrder=this.optionSortOrder*-1
      }
      this.optionSortKey='optionBody'
      this.lastOptionTypeSortKey = this.optionSortKey
    },

//
    bySubjectId(){
      if(this.lastChecklistSubjectSortKey.includes('id')){
        this.checklistSubjectSortOrder=this.checklistSubjectSortOrder*-1
      }
      this.checklistSubjectSortKey='id'
      this.lastChecklistSubjectSortKey = this.checklistSubjectSortKey
    },

    bySubject(){
      if(this.lastChecklistSubjectSortKey.includes('person')){
        this.checklistSubjectSortOrder=this.checklistSubjectSortOrder*-1
      }
      this.checklistSubjectSortKey='person'
      this.lastChecklistSubjectSortKey = this.checklistSubjectSortKey
    },

    bySubjectAddeded(){
      if(this.lastChecklistSubjectSortKey.includes('createDate')){
        this.checklistSubjectSortOrder=this.checklistSubjectSortOrder*-1
      }
      this.checklistSubjectSortKey='createDate'
      this.lastChecklistSubjectSortKey = this.checklistSubjectSortKey
    },


    childFilter(item, queryText, itemText){
      console.log("filter item: "+ item+ queryText + itemText)
      const textFirstname = itemText.raw.firstname.toLowerCase()
      const textlastname = itemText.raw.lastname.toLowerCase()
      const textSecname = itemText.raw.secname.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textFirstname.indexOf(searchText) > -1 ||
          textlastname.indexOf(searchText) > -1  ||
          textSecname.indexOf(searchText) > -1
    },

    closeSubject(){
      this.checklistSubject = null
      this.checklistSubjectDialog = false
    },
    saveSubject(){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      this.$refs.subjectForm.validate().then(responce => {
        if(responce.valid){
          let subject = {
            personId: this.checklistSubject,
            checklistId: this.selectedChecklist.id,
          }

            this.axios.post(apiUrl + '/internal/checklist/subject', subject, jwtTokenConfig)
                .then(response => {
                  if (response.status === 201) {
                    this.checklistSubjects.push(response.data)
                    this.checklistSubject = null
                    this.checklistSubjectDialog = false
                  } else {
                    alert("что то пошло не так")
                  }
                })
                .catch(function (error) {
                  console.log(error)
                  if (error.response.status === 401) {
                    router.push('/login').then(r => {
                      console.log("error: " + r)
                    })
                  }
                })
          }
      })

    },

    deleteSubject(item){
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      if(confirm("Вы точно хотите удалить запись?")){
        this.axios
            .delete(apiUrl +'/internal/checklist/subject/' + item.id, jwtTokenConfig)
            .then(response => {
              if (response.status === 202) {
                this.checklistSubjects.splice(this.checklistSubjects.indexOf(item), 1)
              } else {
                console.log(response)
              }
            })
            .catch(function (error) {
              console.log(error)
              if(error.response.status===401){
                router.push('/login').then(r => {console.log("error: " + r)})
              }
            })
      }

    },

    showSubjectOptions(item){
      this.selectedSubject = item
      console.log(item)
     this.checklistSubjectCriteriaDialog=true

      console.log(item)
    },

  },



  computed: {
    sortedCretarias() {
      return _.orderBy(this.selectedSubject.cretarias, 'id', 'asc');
    },

    questionsCounter(){
      let questions = []
      for (let i = 0; i < 100; i++) {
        questions[i] =i+1
      }
      return questions
    },

    answersCounter(){
      let answers = []
      for (let i = 0; i < 100; i++) {
        answers[i] =i+1
      }
      return answers
    },
//
    filteredСhecklistSubject() {
      const ss = this.checklistSubjectSearchBody.toLowerCase();

      let tempAnswers = this.checklistSubjects

      return tempAnswers.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },

    sortedСhecklistSubject() {
      let tempAnswers = this.filteredСhecklistSubject
      return tempAnswers.sort(dynamicSort(this.checklistSubjectSortKey,this.checklistSubjectSortOrder));
    },

    checklistSubjectPageCount(){
      let l = this.sortedСhecklistSubject.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l/s);
    },

    checklistSubjectPaginatedData(){
      const start = (this.currentChecklistSubjectPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedСhecklistSubject.slice(start, end);
    },

    currentChecklistSubjectPage (){
      if(this.checklistSubjectPageCount < this.checklistSubjectPage){
        return 1
      } else {
        return this.checklistSubjectPage
      }
    },
    //

    filteredOptions() {
      const ss = this.optionSearchBody.toLowerCase();

      let tempAnswers = this.options

      return tempAnswers.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },

    sortedOptions() {
      let tempAnswers = this.filteredOptions
      return tempAnswers.sort(dynamicSort(this.optionSortKey,this.optionSortOrder));
    },

    optionPageCount(){
      let l = this.sortedOptions.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l/s);
    },

    optionPaginatedData(){
      const start = (this.currentOptionPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedOptions.slice(start, end);
    },

    currentOptionPage (){
      if(this.optionPageCount < this.optionPage){
        return 1
      } else {
        return this.optionPage
      }
    },

    filteredCriterias() {
      const ss = this.criteriaSearchBody.toLowerCase();

      let tempQuestions = this.checklistTypeCriterias

      return tempQuestions.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },

    sortedCriterias() {
      let tempQuestions = this.filteredCriterias
      return tempQuestions.sort(dynamicSort(this.criteriaSortKey,this.criteriaSortOrder));
    },

    criteriaPageCount(){
      let l = this.sortedCriterias.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l/s);
    },

    criteriaPaginatedData(){
      const start = (this.currentCriteriaPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedCriterias.slice(start, end);
    },

    currentCriteriaPage (){
      if(this.criteriaPageCount < this.criteriaPage){
        return 1
      } else {
        return this.criteriaPage
      }
    },
//

    filteredChecklists() {
      const ss = this.checklistSearchBody.toLowerCase();

      let tempChecklists = this.checklists

      return tempChecklists.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },

    sortedChecklists() {
      let tempChecklists = this.filteredChecklists
      return tempChecklists.sort(dynamicSort(this.checklistSortKey,this.checklistSortOrder));
    },

    checklistPageCount(){
      let l = this.sortedChecklists.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l/s);
    },

    checklistPaginatedData(){
      const start = (this.currentChecklistPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedChecklists.slice(start, end);
    },

    currentChecklistPage (){
      if(this.checklistPageCount < this.checklistPage){
        return 1
      } else {
        return this.checklistPage
      }
    },
//
    filteredChecklistTypes() {
      const ss = this.checklistTypeSearchBody.toLowerCase();

      let tempChecklists = this.checklistTypes

      return tempChecklists.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },

    sortedChecklistTypes() {
      let tempChecklists = this.filteredChecklistTypes
      return tempChecklists.sort(dynamicSort(this.lastChecklistTypeSortKey,this.checklistSortTypeOrder));
    },

    checklistTypePageCount(){
      let l = this.sortedChecklistTypes.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l/s);
    },

    checklistTypePaginatedData(){
      const start = (this.currentChecklistTypePage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedChecklistTypes.slice(start, end);
    },

    currentChecklistTypePage (){
      if(this.checklistTypePageCount < this.checklistTypePage){
        return 1
      } else {
        return this.checklistTypePage
      }
    },
    //
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },

    user() {
      return this.$store.state.authentication.user
    },

    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {

    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token
      }
    }

    function getChecklists(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/internal/checklist', jwtTokenConfig)
    }
    function getChecklistTypes(jwtTokenConfig, axios) {
      return axios.get(apiUrl +'/internal/checklist/type', jwtTokenConfig)
    }



    Promise.all([
      getChecklists(jwtTokenConfig, this.axios),
      getChecklistTypes(jwtTokenConfig, this.axios),

    ])
        .then(results => {

          if(results[0].status===200){
            this.checklists = results[0].data;
            console.log("1")
          } else if (results[0].status===401){
            router.push('/login').then(r => {console.log("error: " + r)})
          }
          if(results[1].status===200){
            this.checklistTypes = results[1].data;
          } else if (results[1].status===401){
            router.push('/login').then(r => {console.log("error: " + r)})
          }


        })
  }
}
function dynamicSort(property, sortOrder) {
  return function (a,b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result
    if(property==='person'){
       result = (a[property.lastname] < b[property.lastname]) ? -1 : (a[property.lastname] > b[property.lastname]) ? 1 : 0;
    } else {
      result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    }

    return result * sortOrder;
  }
}

</script>
<style>

</style>
