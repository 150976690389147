<template>
  <v-footer
      class=" text-center justify-content-center"

  >
    <v-row class=" text-center justify-content-center  ma-auto">
      <v-col>
        <v-btn
            href="/"
            variant="text"
            class="mx-2"
            rounded="xl"
        >
          Главная
        </v-btn>
        <v-btn
            variant="text"
            class="mx-2"
            rounded="xl"
            @click="showUserAgreement = true"

        >
          Пользовательское соглашение
        </v-btn>
        <v-btn
            variant="text"
            class="mx-2"
            rounded="xl"
            @click="showPersonalAgree = true"
        >
          Политика обработки ПД
        </v-btn>

      </v-col>

      <v-col class="align-self-start mt-4" cols="12">
        <div class="title font-weight-light text-center">
          &copy; {{ (new Date()).getFullYear() }} — Ivan Kotov
        </div>
      </v-col>
    </v-row>

    <v-dialog
        v-model="showPersonalAgree"
        persistent
        fullscreen
        :scrim="false"
    >
      <v-card>
        <v-card-title  style="background: #42A5F5;">
          <span class="text-h5 text-white">Политика в отношении обработки персональных данных</span>
        </v-card-title>
        <v-card-text>
          <h4 class="text-center pa-4">1. Общие положения</h4>

          <p class="text-justify ">Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных ФГБОУ ВО ИГМУ Минздрава РФ (далее – Оператор).</p>
          <p class="text-justify ">Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
          <p class="text-justify ">Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="https://actual-issues-of-surgery.irsmu.ru/" class="card-link">https://actual-issues-of-surgery.irsmu.ru/</a>.</p>

          <h4 class="text-center pa-4">2. Основные понятия, используемые в Политике</h4>

          <p class="text-justify ">Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</p>
          <p class="text-justify ">Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
          <p class="text-justify "> Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://actual-issues-of-surgery.irsmu.ru/" class="card-link">https://actual-issues-of-surgery.irsmu.ru/</a>;</p>
          <p class="text-justify "> Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;</p>
          <p class="text-justify "> Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</p>
          <p class="text-justify ">Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
          <p class="text-justify ">Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</p>
          <p class="text-justify ">Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a href="https://actual-issues-of-surgery.irsmu.ru/" class="card-link">https://actual-issues-of-surgery.irsmu.ru/</a>;</p>
          <p class="text-justify ">Пользователь – любой посетитель веб-сайта, имеющий учётную запись <a href="https://actual-issues-of-surgery.irsmu.ru/" class="card-link">https://actual-issues-of-surgery.irsmu.ru/</a>;</p>
          <p class="text-justify "> Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
          <p class="text-justify ">Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
          <p class="text-justify ">Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</p>
          <p class="text-justify ">Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.</p>

          <h4 class="text-center pa-4"> 3. Оператор может обрабатывать следующие персональные данные Пользователя</h4>

          <p class="text-justify ">Фамилия, имя, отчество, электронный адрес, номера телефонов,  регион проживания, место работы, должность, сведения о стаже и специальности</p>

          <p class="card-text">Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
            Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>

          <h4 class="text-center pa-4">4. Цели обработки персональных данных</h4>

          <p class="card-text">Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем, предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте, Сбор данных и материалов необходимых для оказания услуг.
            Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a class="card-link" href="mailto:rektorat@irkgmu.ru">rektorat@irkgmu.ru</a> с пометкой «Отказ от уведомлений».
            Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>

          <h4 class="text-center pa-4">5. Правовые основания обработки персональных данных</h4>
          <p class="card-text">Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте <a href="https://actual-issues-of-surgery.irsmu.ru/" class="card-link">https://actual-issues-of-surgery.irsmu.ru/</a>. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
            Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).</p>

          <h4 class="text-center pa-4">6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</h4>
          <p class="card-text">Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
            Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
            Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.
            В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора rektorat@irkgmu.ru с пометкой «Актуализация персональных данных».
            Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a class="card-link" href="rektorat@irkgmu.ru">rektorat@irkgmu.ru</a> с пометкой «Отзыв согласия на обработку персональных данных».</p>

          <h4 class="text-center pa-4">7. Трансграничная передача персональных данных</h4>

          <p class="card-text">Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.
            Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.</p>

          <h4 class="text-center pa-4">8. Ответственность сторон</h4>

          <p class="card-text">Администрация Сайта, не исполнившая свои обязательства, несет
            ответственность за убытки, понесенные Пользователем в связи с неправомерным
            использованием персональных данных, в соответствии с законодательством Российской
            Федерации.</p>
          <p class="card-text">В случае утраты или разглашения конфиденциальной информации
            Администрация Сайта не несет ответственности, если данная конфиденциальная
            информация стала публичным достоянием до ее утраты или разглашения, была получена от третьей стороны до момента ее получения Администрацией
            Сайта, была разглашена с согласия Пользователя. </p>
          <h4 class="text-center pa-4">9. Разрешение споров</h4>
          <p class="card-text">До обращения в суд с иском по спорам, возникающим из отношений между
            Пользователем Сайта и Администрацией Сайта, обязательным является предъявление
            претензии (письменного предложения о добровольном урегулировании спора).</p>
          <p class="card-text">Получатель претензии в течение 10 (десяти) календарных дней со дня получения
            претензии письменно уведомляет заявителя претензии о результатах рассмотрения
            претензии.</p>
          <p class="card-text">При недостижении соглашения спор будет передан на рассмотрение в суд в
            соответствии с действующим законодательством Российской Федерации.</p>
          <p class="card-text">К настоящей Политике конфиденциальности и отношениям между
            Пользователем и Администрацией Сайта применяется действующее законодательство
            Российской Федерации.</p>


          <h4 class="text-center pa-4">8. Заключительные положения</h4>
          <p class="card-text">Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a class="card-link" href="rektorat@irkgmu.ru">rektorat@irkgmu.ru</a>.
            В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
            Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://actual-issues-of-surgery.irsmu.ru/.</p>

        </v-card-text>
        <v-card-actions  style="background: #42A5F5;">
          <v-spacer></v-spacer>
          <v-btn
              rounded="pill"
              variant="outlined"
              class="ma-8"
              style="color: #ffffff"
              @click="showPersonalAgree = false"
          >
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="showUserAgreement"
        persistent
        fullscreen
        :scrim="false"
    >
      <v-card>
        <v-card-title style="background: #42A5F5;">
          <span class="text-h5 text-white">Пользовательское cоглашение</span>
        </v-card-title>
        <v-card-text>
          <h4 class="text-center pa-4">1. Общие положения</h4>

          <p class="text-justify ">Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ФГБОУ ВО ИГМУ Минздрава РФ (Администрация) с
            одной стороны и пользователем сайта <a href="https://actual-issues-of-surgery.irsmu.ru/" class="card-link">https://actual-issues-of-surgery.irsmu.ru/</a>  с другой (далее - сайт). </p>
          <p class="text-justify ">Сайт не является средством массовой информации.</p>
          <p class="text-justify ">Используя сайт , Вы соглашаетесь с условиями данного соглашения. Если Вы не согласны с условиями данного соглашения, не используйте сайт.</p>

          <h4 class="text-center pa-4">2. Предмет соглашения</h4>

          <p class="text-justify ">Администрация предоставляет пользователю право на размещение на сайте текстовой информации, аудио, видео и фото материалов, результатов интеллектуальной деятельности, ссылок на материалы, размещенные на других сайтах.</p>

          <h4 class="text-center pa-4"> 3. Права и обязанности сторон</h4>

          <p class="text-justify ">Пользователь имеет право осуществлять поиск и получение информации на сайте, создавать информацию для сайта, информацию сайта в личных некоммерческих целях для осуществления своих должностных функций или получения образовательных услуг.</p>

          <p class="card-text">Администрация имеет право по своему усмотрению и необходимости создавать, изменять, отменять правила, ограничивать доступ к любой информации на сайте. Создавать, изменять, удалять информацию. Удалять учетные записи. Отказывать в регистрации без объяснения причин. </p>

          <p class="card-text">Пользователь обязуется обеспечить достоверность предоставляемой информации.  Обеспечивать сохранность личных данных от доступа третьих лиц.  Обновлять Персональные данные, предоставленные при регистрации, в случае их изменения. Не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации,
            за распространение которой предусмотрена уголовная или административная ответственность. Не нарушать работоспособность сайта. Не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу. Не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам. Не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ.
            Не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ. Не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами.
          </p>

          <p class="text-justify ">Администрация обязуется поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.</p>

          <h4 class="text-center pa-4">4. Ответственность сторон</h4>

          <p class="card-text">Пользователь лично несет полную ответственность за распространяемую им информацию.</p>
          <p class="card-text">Администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами.</p>
          <p class="card-text">В случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса.</p>

          <h4 class="text-center pa-4">5. Условия действия Соглашения</h4>
          <p class="card-text">Данное Соглашение вступает в силу при любом использовании данного сайта.</p>
          <p class="card-text">Соглашение перестает действовать при появлении его новой версии.</p>
          <p class="card-text">Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.</p>
          <p class="card-text">Администрация не оповещает пользователей об изменении в Соглашении.</p>
        </v-card-text>
        <v-card-actions style="background: #42A5F5;">
          <v-spacer></v-spacer>
          <v-btn
              rounded="pill"
              variant="outlined"
              class="ma-8"
              style="color: #ffffff"
              @click="showUserAgreement = false"
          >
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-footer>


</template>

<script>
import { useTheme } from 'vuetify'

export default {
  name: 'SiteFooter',
  setup () {
    const theme = useTheme()

    return {
      theme,
    }
  },
  data() {

    return {
      showPersonalAgree: false,
      showUserAgreement: false,
    }
  },
}
</script>

<style>
.site-footer a {
  text-decoration: none;
}
</style>
